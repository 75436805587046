<template>
  <div class="dynamic-segments">
    <div class="dynamic-segments__table">
      <div class="graph-y">
        <span v-for="num in graphY" :key="num">{{ num }}</span>
      </div>
      <div class="dynamic-segments__list">
        <segmentsItem
          v-for="(segment, index) in segmentsArray"
          :key="segment.id"
          :segment="segment"
          :class="`segment-${index}`"
          @click="emit('setCurrentSegment', segment)"
        ></segmentsItem>
        <span class="graph-line _0"></span>
        <span class="graph-line _1"></span>
        <span class="graph-line _2"></span>
        <span class="graph-line _3"></span>
      </div>

      <baseSegment
        :segment="outflow"
        @click="emit('setCurrentSegment', outflow)"
        :class="{ '_hover _border-radius': !isTableLoading }"
        class="segment-outflow"
      ></baseSegment>
      <div class="dynamic-segments__title fw-600">
        После визита

        <div class="graph-x">
          <span
            v-for="num in graphX"
            :key="num"
            :class="{ 'loading-text': isTableLoading }"
            >{{ num }}</span
          >
        </div>
      </div>
      <baseSegment
        :style="`width:${segment6Width - 0.3}px`"
        :segment="hadVisits"
        @click="emit('setCurrentSegment', hadVisits)"
        :class="{ '_hover ': !isTableLoading }"
        class="segment-hadVisits _border-radius"
      ></baseSegment>
        <baseSegment
          :style="`width: 199px;color: #fff; transform: translateX(4px);`"
          @click="emit('setCurrentSegment', black_list)"
          :segment="black_list"
          :class="{ _hover: !isTableLoading }"
          class="segment-backList _border-radius"
        ></baseSegment>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import baseSegment from "@/components/main-page/segments/baseSegment.vue";
import segmentsItem from "@/components/main-page/segments/segmentsItem.vue";

const store = useStore();

const emit = defineEmits(["setCurrentSegment"]);

const company_rfm = computed(() => store.state.segments.company_rfm);
const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const segmentsArray = computed(
  () => store.state.segments.modifySegments.dynamicSegments
);
const outflow = computed(() => store.state.segments.modifySegments.outflow);
const hadVisits = computed(() => store.state.segments.modifySegments.hadVisits);
const black_list = computed(
  () => store.state.segments.modifySegments.black_list
);

const graphX = computed(() => {
  const levelsArr = [
    +company_rfm.value.r1_level,
    +company_rfm.value.r2_level,
    +company_rfm.value.r3_level,
    +company_rfm.value.r4_level,
  ];

  const floorArr = levelsArr.map((i) => Math.floor(i));

  return floorArr;
});
const graphY = ["4+", 3, 2, 1, 0];

const segment6Width = ref(0);
const segment4Width = ref(0);
function updateSegmentWidth() {
  const segment6 = document.querySelector(".segment-6");
  const segment4 = document.querySelector(".segment-4");
  if (segment6) {
    segment6Width.value = segment6.offsetWidth;
  }
  if (segment4) {
    segment4Width.value = segment4.offsetWidth;
  }
}

const isTestFilial = computed(() => store.getters.isTestFilial);

onMounted(() => {
  updateSegmentWidth();
  // Можно добавить слушатель на изменение размера окна, если нужно
  setTimeout(() => {
    updateSegmentWidth();
    window.addEventListener("resize", updateSegmentWidth);
  }, 500);
});
onUpdated(() => {
  updateSegmentWidth();
});
</script>

<style lang="scss" scoped>
.dynamic-segments {
  position: relative;
  width: 100%;
  border-radius: 12px;

  &__title {
    padding-left: 19px;
    grid-row: span 2;
    grid-column: span 1;

    display: flex;
    align-items: center;
  }
  &__col {
    display: grid;
    grid-row-gap: 6px;
  }

  &__list {
    grid-row: span 1;
    position: relative;
    height: 608px;

    .segment-6:after {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: 2;
      top: -5.7px;
      left: -5.88px;
      right: -5px;
      width: calc(100% + 12px);
      height: 828px;
      border: solid 1px #cccccc;
      border-radius: 15px 0 15px 15px;
      display: block;
    }
  }

  .graph-y {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #a1a2a2;
    align-items: center;
    grid-row: span 2;
    font-size: 16px;
    font-weight: 400;
    height: 81.5%;
    width: 84%;
    margin-top: 90px;

    span {
      height: 11.5px;
    }
  }
  .graph-x {
    flex-grow: 1;
    position: relative;
    color: #a1a2a2;
    font-size: 16px;
    font-weight: 400;

    span {
      top: -9px;
      position: absolute;
      &:nth-child(1) {
        left: 23.9%;
      }
      &:nth-child(2) {
        left: 51%;
      }
      &:nth-child(3) {
        left: 76%;
      }
      &:nth-child(4) {
        left: 101%;
      }
    }
  }

  &__table {
    display: grid;
    grid-template-columns: 33px auto 205px;
    grid-template-rows: auto 35px;
    align-items: end;
    grid-column: span 2;

    align-items: center;
  }
  .segment-outflow {
    height: 100%;
    border-radius: 15px;
    border: solid 4px #fff;
  }

  .segment-hadVisits {
    grid-row: span 3;
    grid-column: 2;
    margin-left: 0px;
  }
}
.graph-line {
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  width: 0.5px;
  background: #cccccc;
  z-index: 2;
  bottom: -16px;

  &:after {
    content: ".";
    position: absolute;
    bottom: -9px;
    left: -5.6px;
    font-size: 40px;
    color: #cccccc;
  }
  &._0 {
    left: 32.1%;
    background: transparent;
    &:after {
      left: -10.2px;
    }
  }
  $col1Width: 32.1875%;
  $col2Width: 24.0625%;
  $col3Width: 21.875%;
  $col4Width: $col3Width;

  &._1 {
    left: calc($col1Width + $col2Width - 5.5px);
    height: calc(66% + 8px);
    &:after {
      left: -5.6px;
    }
  }
  &._2 {
    height: calc(33% + 8px);
    left: calc($col1Width + $col2Width + $col3Width - 5.9px);

    &:after {
      left: -5.6px;
    }
  }
  &._3 {
    left: calc($col1Width + $col2Width + $col3Width + $col4Width - 3.9px);
    &:after {
      left: -5.6px;
    }
  }
}
</style>
