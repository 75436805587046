import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

import { ISegment } from "@/types/typesOfApi";
import { computed } from "vue";
import { useStore } from "vuex";

type Props = {
  segment: ISegment;
};
/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'baseSegment',
  props: {
    segment: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore();

function openSettings() {
  store.state.segments.showSegmentSettings = true
}

const predictedIncomeShow = computed(() => {
  return (
    props.segment.predicted_income &&
    store.state.segments.predictedIncomeShow === "on"
  );
});

function getIndexText(index) {
  const modIds = [123, 121314]

  if (modIds.includes(index)) {
    return index === 123 ? '+' : '₽'
  } else {
    return index
  }
}

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const segment = computed(() => props.segment);

function addSpacesForNumber(str) {
  if (!isNaN(str)) {
    return str.toLocaleString();
  } else {
    return "0";
  }
}

const totla_percent = computed(() => {
  const num = +segment.value.total_clients_percent;

  return num.toFixed(1);
});

const totalClientStr = computed(() =>
  addSpacesForNumber(segment.value.total_clients)
);
const totalPercentStr = computed(
  () => addSpacesForNumber(totla_percent.value) + "%"
);

const currentTwoParamName = computed(() => {
  if (predictedIncomeShow.value) {
    return "Потенциал выручки";
  } else {
    return segment.value.average_check ? "Средний чек" : "На сумму";
  }
});
const currentTwoParamValue = computed(() => {
  if (predictedIncomeShow.value) {
    return (
      addSpacesForNumber(Math.trunc(+segment.value.predicted_income)) + " ₽"
    );
  } else {
    if (segment.value.average_check) {
      return (
        addSpacesForNumber(Math.trunc(+segment.value.average_check)) + " ₽"
      );
    } else {
      return addSpacesForNumber(Math.trunc(+segment.value.check)) + " ₽";
    }
  }
});

const segmentStyles = computed(() => {
  const bgColor = segment.value.background_color;
  const borderColor = segment.value.border_color;
  return `
  background-color:${borderColor ? "#fff" : bgColor}; 
  border: solid 2px ${borderColor}; 
  padding:${borderColor ? "20px" : "22px"}`;
});

return (_ctx: any,_cache: any) => {
  return (segment.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["base-segment _base-positions", segment.value.border_color ? '_hoverBorderPlus' : '']),
        style: _normalizeStyle(segmentStyles.value)
      }, [
        (segment.value.index)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "base-segment__number _number",
              style: _normalizeStyle(`border-color:${segment.value.text_color}`),
              innerHTML: getIndexText(segment.value.index)
            }, null, 12, _hoisted_1))
          : _createCommentVNode("", true),
        (segment.value.smileLogo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "base-segment__smile",
              src: require(`@/assets/images/segments-smiles/${segment.value.smileLogo}`),
              alt: ":)"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (segment.value.smileAnimation)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              class: "base-segment__animation",
              src: 
        require(`@/assets/images/segments-smiles/${segment.value.smileAnimation}`)
      ,
              alt: ":)"
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "base-segment__name",
          innerHTML: segment.value.name
        }, null, 8, _hoisted_4),
        _createElementVNode("div", {
          class: _normalizeClass(["base-segment__stats", { 'loading-text': isTableLoading.value }])
        }, [
          _createElementVNode("span", {
            class: "fw-600",
            innerHTML: totalClientStr.value
          }, null, 8, _hoisted_5),
          _createTextVNode(" — " + _toDisplayString(totalPercentStr.value), 1)
        ], 2),
        _createElementVNode("div", {
          class: "base-segment__name",
          innerHTML: currentTwoParamName.value
        }, null, 8, _hoisted_6),
        _createElementVNode("div", {
          class: _normalizeClass(["base-segment__check", { 'loading-text': isTableLoading.value }]),
          innerHTML: currentTwoParamValue.value
        }, null, 10, _hoisted_7),
        _renderSlot(_ctx.$slots, "default")
      ], 6))
    : _createCommentVNode("", true)
}
}

})