<template>
  <div class="segments-menu">
      <segmentRecomendation
        :segment="currentSelectSegment"
        v-if="currentSelectSegment"
      ></segmentRecomendation>
      <defaultPopup :open="true" v-if="showSegmentSettings">
        <segmentSettings ></segmentSettings>
      </defaultPopup>
      
  </div>
</template>

<script lang='ts' setup>
import { computed, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import segmentSettings from './segmentSettings'
import segmentRecomendation from "@/components/main-page/segments/segmentRecomendation.vue";
import defaultPopup from '@/components/UI/bloks/default-popup.vue'

const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const showSegmentSettings = computed(() => store.state.segments.showSegmentSettings)

const currentSettingsSegment = computed(
  () => store.state.segments.currentSettingSegment
);

const currentSelectSegment = computed(
  () => store.state.segments.currentSelectSegment
);

onUnmounted(() => {
  store.state.segments.showSegmentSettings = false
})

</script>

<style lang='scss' scoped>
.segments-menu {
  position: relative;
  width: 100%;

  &__btns {
    position: absolute;
    top: 2px;
    right: 0;
    display: flex;
    gap:  32px
  }
}


</style>