import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "segments-menu" }

import { computed, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import segmentSettings from './segmentSettings'
import segmentRecomendation from "@/components/main-page/segments/segmentRecomendation.vue";
import defaultPopup from '@/components/UI/bloks/default-popup.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentsMenu',
  setup(__props) {

const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const showSegmentSettings = computed(() => store.state.segments.showSegmentSettings)

const currentSettingsSegment = computed(
  () => store.state.segments.currentSettingSegment
);

const currentSelectSegment = computed(
  () => store.state.segments.currentSelectSegment
);

onUnmounted(() => {
  store.state.segments.showSegmentSettings = false
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (currentSelectSegment.value)
      ? (_openBlock(), _createBlock(segmentRecomendation, {
          key: 0,
          segment: currentSelectSegment.value
        }, null, 8, ["segment"]))
      : _createCommentVNode("", true),
    (showSegmentSettings.value)
      ? (_openBlock(), _createBlock(defaultPopup, {
          key: 1,
          open: true
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(segmentSettings))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})