<template>
  <div :class="isOpen ? '_open' : ''">
    <div @click="toggle" style="cursor: pointer">
      <slot name="header"></slot>
    </div>
    <div
      ref="content"
      :style="`max-height: ${isOpen ? contentHeight : 0}px;`"
      class="content"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";

const props = defineProps({
  defaultOpen: Boolean,
});

const isOpen = ref(false);
const contentHeight = ref(0);
const content = ref(null);

function updateContentHeight() {
  if (content.value) {
    contentHeight.value = content.value.scrollHeight;
  }
}

onMounted(() => {
  nextTick(() => {
    updateContentHeight();
    setTimeout(() => {
      if (props.defaultOpen) {
        isOpen.value = true;
      }
    }, 300);
  });
});

watch(isOpen, (newVal) => {
  if (newVal) {
    nextTick(() => {
      updateContentHeight();
    });
  }
});

watch(
  () => content.value?.innerHTML,
  () => {
    updateContentHeight();
  }
);

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped>
.content {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
</style>
