import axios from "axios";
import { Module } from "vuex";

const segmentssettingsModule: Module<any, any> = {
  namespaced: true,
  state: {
    allCategories: null,
    selectedCategories: [],
    currentCategoryPage: 1,
    settingsLoader: false,
  },
  mutations: {
    setSelectedCategories(state, categories) {
      const selectedCategories = state.allCategories.filter((category: any) =>
        categories.includes(category.category_id)
      );
      state.selectedCategories = selectedCategories;
    },
  },
  getters: {},
  actions: {
    async getClientsCategories(context) {
      return await axios({
        url: "https://rfm.botzavod.com/api/get-company-categories",
        params: {
          salon_id: context.rootState.salon_id,
        },
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res) => {
        context.state.allCategories = res.data.categories;
      });
    },
    async postClientsCategories(context) {
      context.rootState.mainLoader = true
      const IdsArr: number[] = [];
      context.state.selectedCategories.forEach((category: any) => {
        IdsArr.push(category.category_id);
      });

      console.log(IdsArr);
      return await axios({
        method: "post",
        url: "https://rfm.botzavod.com/api/set-black-list-categories",
        data: {
          salon_id: context.rootState.salon_id,
          categories: IdsArr,
        },
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res) => {
        console.log(res);
        setTimeout(() => {
          context.dispatch("segments/getSegmentsTable", {}, { root: true }).then(() => {
            context.rootState.mainLoader = false
          })
        }, 200);
      });
    },
    async getPostedClientsCategories(context) {
      context.state.settingsLoader = true;
      return await axios({
        url: "https://rfm.botzavod.com/api/get-company-black-list-categories",
        params: {
          salon_id: context.rootState.salon_id,
        },
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res) => {
        context.state.settingsLoader = false;
        console.log(res.data.categories);
        if (res.data.categories) {
          context.commit("setSelectedCategories", res.data.categories);
        }
      });
    },
  },
  modules: {},
};

export default segmentssettingsModule;
