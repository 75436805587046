<template>
  <div
    class="reactivation-table"
    :class="isShowWhatsApp ? '__state1' : '__state2'"
  >
    <div class="reactivation-table__row _head">
      <div class="reactivation-table__col">ID</div>
      <button
        class="reactivation-table__col _status"
        :class="{ _open: isShowFilterStatuses }"
        @click="isShowFilterStatuses = !isShowFilterStatuses"
      >
        Статус
        <div class="fs-12px">
          {{ currentReactivationFilterStatus.description }}
        </div>
        <div class="fs-12px statuses-list" v-if="isShowFilterStatuses">
          <button
            class="statuses-list__item"
            v-for="status in reactivationStatuses"
            @click="setNewReactivationStatus(status.id)"
            :key="status.id"
            v-html="status.description"
          ></button>
        </div>
      </button>
      <div class="reactivation-table__col">Название</div>
      <div class="reactivation-table__col">Сегмент</div>
      <div class="reactivation-table__col" v-if="isShowWhatsApp">
        Отправлено
      </div>
      <div class="reactivation-table__col" v-if="isShowWhatsApp">
        Доставлено
      </div>
      <div class="reactivation-table__col" v-if="isShowWhatsApp">Прочитано</div>
      <div class="reactivation-table__col">Записался</div>
      <div class="reactivation-table__col">Пришел</div>
      <div class="reactivation-table__col">Оплатил</div>
      <div class="reactivation-table__col">Средний чек</div>
      <div class="reactivation-table__col _revenue">
        Выручка
        <div class="fs-10px">Бонусы | Рубли</div>
      </div>
      <div class="reactivation-table__col"></div>
    </div>
    <div v-if="reactivationList && reactivationList.find((i) => i.goal)">
      <div
        class="reactivation-table__row"
        v-for="activation in reactivationList"
        :key="activation.id"
      >
      <div class="reactivation-table__col fs-12">#{{activation.id}}</div>
        <div
          class="reactivation-table__col"
          v-html="getStatus(activation)"
        ></div>
        <div class="reactivation-table__col" v-html="activation.name"></div>
        <div
          class="reactivation-table__col"
          v-html="getCurrentSegment(activation)"
        ></div>

        <div class="reactivation-table__col" v-if="isShowWhatsApp">-</div>
        <div class="reactivation-table__col" v-if="isShowWhatsApp">-</div>
        <div class="reactivation-table__col" v-if="isShowWhatsApp">-</div>
        <div
          class="reactivation-table__col"
          v-html="getOrderClients(activation)"
        ></div>
        <div
          class="reactivation-table__col"
          v-html="getCameClients(activation)"
        ></div>
        <div
          class="reactivation-table__col"
          v-html="getPaidClient(activation)"
        ></div>
        <div
          class="reactivation-table__col"
          v-html="getAvarageCheck(activation)"
        ></div>
        <div
          class="reactivation-table__col"
          v-html="getRevenue(activation)"
        ></div>
        <div class="reactivation-table__col">
          <button
            class="reactivation-table__btn"
            @click="emit('openActivation', activation)"
          >
            <img :src="require('@/assets/images/eye-icon.svg')" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="reactivation-table__err">
      <div v-html="reactivationTableErrText">
      </div>
      <div v-if="getReactivationLoading" class="small-loader"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, defineEmits } from "vue";
import { useStore } from "vuex";
import reactivationItemPage from "@/components/reactivation/reactivationItemPage.vue";
import getSegmentByIndex from "@/functions/getSegmentByIndex";

const store = useStore();

const emit = defineEmits(["openActivation"]);

const getReactivationLoading = computed(
  () => store.state.reactivation.getReactivationLoading
);

const reactivationList = computed(
  () => store.state.reactivation.reactivationList
);

const reactivationStatuses = computed(
  () => store.state.reactivation.reactivationStatuses
);

const reactivationTableErrText = computed(() => {
  if (getReactivationLoading.value) {
    return 'Загрузка'
  } else {
    if (currentReactivationFilterStatus.value.id === null) {
      return 'Реактивации не найдены, чтобы создать реактивацию, передйите в  раздел "сегменты", выберите нужный сегмент, и нажмине на кнопку "Реактивация"'
    } else {
      return 'Реактивации не найдены'
    }
  }
})

const currentReactivationFilterStatus = computed(() => {
  const statusId = store.state.reactivation.currentReactivationFilterStatusId;
  return reactivationStatuses.value.find((i) => i.id === statusId);
});

const isShowFilterStatuses = ref(false);

function setNewReactivationStatus(status_id) {
  store.commit("reactivation/setCurrentReactivationStatus", status_id);
}

const isShowWhatsApp = computed(() => store.state.auth.isShowWhatsApp);

function getStatus(activation) {
  return activation.goal.description;
}
function getCurrentSegment(activation) {
  const modifySegments = store.state.segments.modifySegments;
  const segmentIndex = activation.segment_id
  return getSegmentByIndex(modifySegments, segmentIndex)?.name
}
function formatNumber(value) {
  return Math.trunc(value).toString();
}

let currentActivation = ref(null);

function closeReactivationItem() {
  currentActivation.value = null;
}

function getClientsInfo(activation, countKey, percentKey) {
  if (typeof activation.goal[countKey] === "number") {
    const countValue = formatNumber(activation.goal[countKey]);
    const percentValue = activation.goal[percentKey]
      ? formatNumber(activation.goal[percentKey]) + "%"
      : "0%";
    return `<span>${countValue}</span> / <span>${percentValue}</span>`;
  } else {
    return "- / -";
  }
}

function getPluralForm(number, forms) {
  const n = Math.abs(number) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return forms[1];
  }
  if (n1 === 1) {
    return forms[0];
  }
  return forms[2];
}

function getRevenue(activation) {
  const revenue = activation.goal.revenue
    ? activation.goal.revenue.toLocaleString() + " ₽"
    : "0";

  const revenueLoyalty = activation.goal.revenue_loyalty
    ? activation.goal.revenue_loyalty.toLocaleString() + " ₽"
    : "0";

  return `${revenueLoyalty} | ${revenue}`;
}
function getAvarageCheck(activation) {
  return activation.goal.average_check
    ? activation.goal.average_check.toLocaleString() + " ₽"
    : "-";
}

function getIsShowParams(activation) {
  return [1, 2, -1].includes(activation.goal.is_active);
}

function getOrderClients(activation) {
  return getClientsInfo(
    activation,
    "signed_up_clients_count",
    "signed_up_clients_percent"
  );
}

function getCameClients(activation) {
  return getClientsInfo(
    activation,
    "came_clients_count",
    "came_clients_percent"
  );
}

function getPaidClient(activation) {
  return getClientsInfo(
    activation,
    "paid_clients_count",
    "paid_clients_percent"
  );
}

function getChannel(activation) {
  return activation.channel[0] === "SMS" ? "WhatsApp" : "Email";
}
</script>

<style lang="scss" scoped>
.reactivation-table {
  width: 1500px;
  border: solid 2px #e5e7ed;
  border-radius: 20px;

  &__btn {
    cursor: pointer;
    transform: rotate(0deg) translateY(2px);
    transition: 0.2s;
    margin: -20px;
    padding: 20px;  

    img {
      width: 25px;
      height: 15px;
    }
  }
  &__row {
    display: grid;
    font-weight: 500;

    grid-template-rows: 1fr 0px;
    grid-column-gap: 24px;
    padding: 23px 15px;
    border-bottom: solid 2px #e5e7ed;
    font-size: 15px;
    cursor: default;
    transition: 0.2s;

    &:last-child {
      border-bottom: none;
    }
    &._head {
      font-size: 18px;
      font-weight: 600;
      padding: 15px 15px 23px 15px;
    }
  }
  &__col {
    display: flex;
    align-items: center;
  }
  &__col:nth-child(n + 5):not(:last-child) {
    justify-content: center;
  }

  &__col._revenue {
    position: relative;
    bottom: -8px;
    gap: 5px;
    flex-direction: column;
  }
  &__col._status {
    position: relative;
    bottom: -8px;
    gap: 5px;
    align-items: start;
    flex-direction: column;
    cursor: pointer;

    &:after {
      content: "" / "Выбрать статус";
      width: 13px;
      height: 15px;
      position: absolute;
      top: 1px;
      right: 11px;
      transition: 0.1s;
      background: url(~@/assets/images/reactivation-open.svg) no-repeat center /
        100%;
    }
    &._open:after {
      transform: rotate(-180deg);
      top: -2px;
    }
  }

  &__err {
    font-weight: 500;

    padding: 23px;
    font-size: 16px;
    cursor: default;
    transition: 0.2s;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__content {
    padding-top: 37px;
    overflow: hidden;
    grid-column: 1 / 9;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }
  &.__state1 {
    .reactivation-table__row {
      grid-template-columns: 50px 114px 150px 220px repeat(6, minmax(0, 1fr)) 1.5fr 24px;
    }
  }
  &.__state2 {
    .reactivation-table__row {
      grid-template-columns: 50px 114px 150px 220px repeat(4, minmax(0, 1fr)) 1.5fr 24px;
    }
  }
}
.statuses-list {
  position: absolute;
  top: 63%;
  left: -12px;
  width: 130%;
  background: #fff;
  padding: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px #5d5c5c;
  &__item {
    padding: 7px;
    cursor: pointer;

    &:hover {
      background: #e5e7ed;
    }
  }
}
</style>
<style lang="scss">
.reactivation-table {
  &__col:nth-last-child(-n + 6):nth-last-child(n + 4) {
    gap: 3px;
    // span {
    //   display: inline-block;
    // }
    // span:first-child {
    //   width: 10%;
    //   text-align: right;
    // }
    // span:last-child {
    //   width: 46px;
    // }
  }
}
</style>
