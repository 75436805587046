<template>
  <div class="segments-search-wrap" ref="searchWrap">
    <form class="segments-search" @submit.prevent>
      <input
        :placeholder="inputPlaceholder"
        type="text"
        class="segments-search__input"
        :class="{ 'loading-block': currentSearchLoading || isTableLoading }"
        v-model="phoneNumber"
        @input="formatPhoneNumber"
        @focus="phoneFocus"
        @blur="isInputActive = false"
      />
      <img
        src="~@/assets/images/search-icon.svg"
        alt=""
        class="segments-search__icon"
      />

      <button class="segments-search__btn" @click="seacrhByPhone">Найти</button>
    </form>

    <div class="search-result" v-if="currentSearchClient?.client_id">
      <div class="search-result__row">Клиент найден!</div>
      <div class="search-result__row">
        id: {{ currentSearchClient.client_id }}
      </div>
      <div class="search-result__row">
        Сегмент:
        <span class="search-result__seg" @click="openSegment">{{
          currentSearchSegment
        }}</span>
      </div>
    </div>
    <div class="search-result" v-else-if="currentSearchClient?.description">
      <div class="search-result__row">{{currentSearchClient?.description}}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount, defineEmits } from "vue";
import { useStore } from "vuex";

/* eslint-disable-next-line */
const emit = defineEmits(['openSegment']);


const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const searchWrap = ref(null);

function phoneFocus() {
  isInputActive.value = true;
}

const phoneNumber = computed({
  get: () => store.state.segments.clients.currentSearchClientPhone,
  set: (newValue) =>
    (store.state.segments.clients.currentSearchClientPhone = newValue),
});

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const formatPhoneNumber = () => {
  phoneNumber.value = phoneNumber.value.replace(/\D/g, "");

  if (phoneNumber.value.startsWith("8")) {
    phoneNumber.value = "7" + phoneNumber.value.slice(1);
  }

  if (phoneNumber.value.length > 14) {
    phoneNumber.value = phoneNumber.value.slice(0, 14);
  }

  if (phoneNumber.value.length > 0) {
    phoneNumber.value = "+" + phoneNumber.value;
  }
};

function seacrhByPhone() {
  store.dispatch("segments/clients/searchClientByPhone");
}

function reset() {
  store.state.segments.clients.currentSearchClient = null;
  phoneNumber.value = "";
}

let isInputActive = ref(false);

const inputPlaceholder = computed(() => {
  return isInputActive.value
    ? "+7 000 000 00 00 "
    : "Найти клиента по телефону";
});

const currentSearchLoading = computed(
  () => store.state.segments.clients.currentSearchLoading
);
const currentSearchClient = computed(
  () => store.state.segments.clients.currentSearchClient
);
const currentSearchClientSegmentId = computed(
  () => currentSearchClient.value.segment_id
);

function getSegmentNameByIndex(index) {
  const numericIndex = Number(index);

  const segments = modifySegments.value;

  for (const key in segments) {
    const segment = segments[key];
    console.log(segment.index,numericIndex )
    if (Number(segment.index) === numericIndex) {
      return segment.name;
    }
  }

  for (const segment of segments.dynamicSegments) {
    if (Number(segment.index) === numericIndex) {
      return segment.name;
    }
  }

  for (const segment of segments.awaitingSegments) {
    if (Number(segment.index) === numericIndex) {
      return segment.name;
    }
  }

  return null;
}

const currentSearchSegment = computed(() => {
  const currentSegmentId = currentSearchClientSegmentId.value;
  if (typeof currentSegmentId === "number") {
    const currentSegmentName = getSegmentNameByIndex(currentSegmentId);
    return currentSegmentName;
  } else {
    return null;
  }
});

function handleClickOutside(event) {
  if (searchWrap.value && !searchWrap.value.contains(event.target)) {
    reset();
  }
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

function openSegment() {
  console.log('inn')
  store.state.segments.clients.defaultRecomendationPageClients = currentSearchClient.value.page;
  store.state.segments.clients.recomedationPageClientsShow = true;
  store.state.segments.clients.scrollToClientId = `client_id_` + currentSearchClient.value.client_id;
  
  emit("openSegment", currentSearchClientSegmentId.value);
  // reset()
}
</script>

<style lang="scss" scoped>
.segments-search-wrap {
  position: relative;
}
.segments-search {
  border: solid 1px #cccccc;
  overflow: hidden;
  border-radius: 9px;
  position: relative;
  width: 210px;
  height: 30px;

  &__icon {
    position: absolute;
    top: 6px;
    left: 4px;
    z-index: 2;
    width: 15px;
    height: 15px;
    filter: contrast(10%);
    transition: 0.1s;
    pointer-events: none;
  }
  &__btn {
    position: absolute;
    z-index: 2;
    height: 30px;
    background: #82d3ebab;
    padding: 3px 10px;
    font-size: 11px;
    right: -55px;
    cursor: pointer;
    transition: 0.4s;
  }

  &__input {
    outline: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    padding-left: 25px;
    font-size: 11px;
    transition: 0.1s;
  }

  &__input:focus {
    padding-left: 10px;
  }
  &__input:focus + &__icon {
    left: -20px;
  }
  &__input:focus + &__icon + &__btn {
    right: 0px;
  }
}

.search-result {
  position: absolute;
  background: #fff;
  z-index: 2;
  padding: 10px;

  width: 100%;

  border: solid 1px #cccccc;
  border-radius: 9px 9px;
  top: 29px;
  font-size: 12px;
  line-height: 132%;

  &__seg {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
