<template>
  <div class="overlay">
    <div class="overlay__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  z-index: 50;
  overflow: auto;

  &__inner {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
  }
}
</style>
