<template>
  <div class="segment-settings" :class="{ '--loading': isSettingsLoading }">
    <black-list-settings></black-list-settings>

    <div class="segment-settings__btns">
      <button class="btn _trans" @click="closeSettings">
        Закрыть без изменений
      </button>
      <button class="btn" @click="saveSettings">Сохранить</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import { computed, ref } from "vue";

import blackListSettings from "@/components/main-page/segments/segmentsSettings/blackListSettings.vue";

import segmentRecomendation from "@/components/main-page/segments/segmentRecomendation.vue";

const store = useStore();

const isSettingsLoading = computed(
  () => store.state.segments.settings.settingsLoader
);

function close() {
  store.state.segments.showSegmentSettings = false;
  store.state.segments.showSegmentActions = false;
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}

function closeSettings() {
  close();
}
function saveSettings() {
  store.dispatch("segments/settings/postClientsCategories");
  close();
}
</script>

<style lang="scss" scoped>
.segment-settings {
  max-width: 796px;
  &__btns {
    display: flex;
    justify-content: space-between;
    button {
      width: 45%;
    }
  }
}
</style>
