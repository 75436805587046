import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useStore } from "vuex";
import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import { computed, ref } from "vue";

import blackListSettings from "@/components/main-page/segments/segmentsSettings/blackListSettings.vue";

import segmentRecomendation from "@/components/main-page/segments/segmentRecomendation.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'segmentSettings',
  setup(__props) {

const store = useStore();

const isSettingsLoading = computed(
  () => store.state.segments.settings.settingsLoader
);

function close() {
  store.state.segments.showSegmentSettings = false;
  store.state.segments.showSegmentActions = false;
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}

function closeSettings() {
  close();
}
function saveSettings() {
  store.dispatch("segments/settings/postClientsCategories");
  close();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["segment-settings", { '--loading': isSettingsLoading.value }])
  }, [
    _createVNode(blackListSettings),
    _createElementVNode("div", { class: "segment-settings__btns" }, [
      _createElementVNode("button", {
        class: "btn _trans",
        onClick: closeSettings
      }, " Закрыть без изменений "),
      _createElementVNode("button", {
        class: "btn",
        onClick: saveSettings
      }, "Сохранить")
    ])
  ], 2))
}
}

})