import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "black-list-settings" }
const _hoisted_2 = { class: "select-categories" }
const _hoisted_3 = {
  key: 0,
  class: "select-categories__dropdown"
}
const _hoisted_4 = ["onMousedown"]
const _hoisted_5 = {
  key: 1,
  class: "select-categories__dropdown"
}
const _hoisted_6 = { class: "select-categories__list" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 1 }

import { useStore } from "vuex";
import defaultAccordion from "@/components/UI/default-accordion.vue";
import { ref, computed, onMounted, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'blackListSettings',
  setup(__props) {

const store = useStore();

// Получаем категории из Vuex
const categories = computed(() => store.state.segments.settings.allCategories);

const searchQuery = ref("");
const filteredCategories = ref([]);
const selectedCategories = computed({
  get: () => store.state.segments.settings.selectedCategories,
  set: (newValue) => {
    store.state.segments.settings.selectedCategories = newValue;
  },
});
const showDropdown = ref(false);
const categoryInput = ref(null);

const filterCategories = () => {
  const query = searchQuery.value.toLowerCase();
  filteredCategories.value = categories.value.filter((category) =>
    category.title.toLowerCase().includes(query)
  );
};

const toggleCategory = (category) => {
  if (isSelected(category)) {
    removeCategory(category);
  } else {
    selectedCategories.value.push(category);
  }
  searchQuery.value = ""; // Сброс поиска после выбора
  filterCategories(); // Обновление списка категорий
  categoryInput.value.blur(); // Снять фокус с поля ввода
};

const removeCategory = (category) => {
  selectedCategories.value = selectedCategories.value.filter(
    (selected) => selected.category_id !== category.category_id
  );
};

const isSelected = (category) => {
  return selectedCategories.value.some(
    (selected) => selected.category_id === category.category_id
  );
};

const hideDropdown = () => {
  setTimeout(() => {
    showDropdown.value = false;
  }, 100);
};

// Загружаем категории при монтировании компонента
onMounted(() => {
  store.dispatch("segments/settings/getClientsCategories").then(() => {
    filteredCategories.value = categories.value;

    store
      .dispatch("segments/settings/getPostedClientsCategories")
  });
});

const isSettingsLoading = computed(
  () => store.state.segments.settings.settingsLoader
);

function close() {
  store.state.segments.showSegmentSettings = false;
  store.state.segments.showSegmentActions = false;
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}
function saveSettings() {
  store.dispatch("segments/settings/postClientsCategories")
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(defaultAccordion, {
      defaultOpen: false,
      class: "black-list-settings__accordion segment-settings__accordion"
    }, {
      header: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("span", { class: "fs-14px" }, "Как работает сегмент «Чёрный список»", -1)
      ])),
      content: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "segment-settings__desc" }, [
          _createElementVNode("p", { class: "font-ital fw-500" }, " Чёрный список – это специальный сегмент, куда вы можете переместить любого клиента из вашей базы, назначив ему соответствующую категорию в системе YCLIENTS. "),
          _createElementVNode("br"),
          _createElementVNode("p", { class: "fw-600" }, "Добавление клиента в Чёрный список"),
          _createElementVNode("br"),
          _createElementVNode("p", { class: "font-ital" }, " Чтобы отправить клиента в этот сегмент, необходимо: "),
          _createElementVNode("br"),
          _createElementVNode("ol", { class: "default-ol fs-14px" }, [
            _createElementVNode("li", null, " В системе YCLIENTS создайте и назначьте клиенту категорию «Чёрный список» или любую другую. "),
            _createElementVNode("li", null, " Настройте Сегмент “Чёрный список” в сервисе Сегменты, выбрав Категории для исключения клиентов из рассылки. "),
            _createElementVNode("li", null, " Все клиенты помеченные выбранной категорией перейдут в Сегмент Чёрный список. "),
            _createElementVNode("li", null, " Как только клиент будет помечен в системе YCLIENTS категорией он будет исключен автоматически и сразу из активных сегментов в сервисе Сегменты. ")
          ]),
          _createElementVNode("br"),
          _createElementVNode("p", { class: "fw-600" }, "Удаление клиента из Чёрного списка"),
          _createElementVNode("br"),
          _createElementVNode("p", { class: "font-ital" }, " Если вы хотите вернуть клиента в рабочие сегменты: "),
          _createElementVNode("br"),
          _createElementVNode("ol", { class: "default-ol fs-14px" }, [
            _createElementVNode("li", null, "Откройте клиентскую базу в YCLIENTS."),
            _createElementVNode("li", null, " Настройте Сегмент “Чёрный список” в сервисе Сегменты, выбрав Категории для исключения клиентов из рассылки. "),
            _createElementVNode("li", null, "Удалите у него категорию «Чёрный список»")
          ]),
          _createElementVNode("br"),
          _createElementVNode("p", null, " После этого клиент автоматически и мгновенно вернётся в свой исходный сегмент в сервисе Сегменты. "),
          _createElementVNode("br"),
          _createElementVNode("p", { class: "fw-600" }, "Функции чёрного списка"),
          _createElementVNode("br"),
          _createElementVNode("p", { class: "font-ital" }, "При добавлении клиента в Чёрный список:"),
          _createElementVNode("br"),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, " ✅ Клиент исключается из рабочей области активных сегментов. "),
            _createElementVNode("li", null, " ✅ Число активных клиентов уменьшается, позволяя видеть реальное количество работающих клиентов. "),
            _createElementVNode("li", null, "✅ Клиент не получает уведомления из сервиса Сегменты."),
            _createElementVNode("li", null, " ✅ Клиент не исключается из расчёта его текущих сегментов – в отчётах видно, в каких сегментах он мог бы находиться на текущий день. ")
          ]),
          _createElementVNode("br"),
          _createTextVNode(" Таким образом, Чёрный список позволяет гибко управлять клиентской базой и исключать неактуальных клиентов, сохраняя при этом полную аналитику их поведения. ")
        ], -1)
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        ref_key: "categoryInput",
        ref: categoryInput,
        type: "text",
        class: "select-categories__search",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (showDropdown.value = true)),
        onBlur: hideDropdown,
        onInput: filterCategories,
        placeholder: "Добавить категорию клиентов из YCLIENTS"
      }, null, 544), [
        [_vModelText, searchQuery.value]
      ]),
      (showDropdown.value && filteredCategories.value)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredCategories.value, (category) => {
              return (_openBlock(), _createElementBlock("li", {
                key: category.category_id,
                onMousedown: _withModifiers(($event: any) => (toggleCategory(category)), ["prevent"]),
                class: _normalizeClass({ selected: isSelected(category) })
              }, _toDisplayString(category.title), 43, _hoisted_4))
            }), 128))
          ]))
        : (showDropdown.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_5, _cache[4] || (_cache[4] = [
              _createElementVNode("li", null, "Категории не найдены", -1)
            ])))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "fw-600 select-categories__title" }, "Выбранные категории:", -1)),
        (selectedCategories.value.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategories.value, (category) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: category.category_id
                }, [
                  _createTextVNode(_toDisplayString(category.title) + " ", 1),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (removeCategory(category))
                  }, "Удалить", 8, _hoisted_8)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("ul", _hoisted_9, _cache[5] || (_cache[5] = [
              _createElementVNode("li", null, "Нет выбранных категорий", -1)
            ])))
      ])
    ]),
    _createElementVNode("div", { class: "black-list-settings__btns" }, [
      _createElementVNode("button", {
        class: "btn w-100",
        onClick: saveSettings
      }, "Сохранить")
    ])
  ]))
}
}

})