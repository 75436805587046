import axios, { AxiosResponse, AxiosError } from "axios";
import { Module } from "vuex";

const defaultReactivationTemplate = `Готовы к приятному сюрпризу? Скидка 20% — сегодня!

Акция действует до конца недели, и вы можете забронировать удобное время прямо сейчас.

Мы понимаем, как важно уделить время себе и расслабиться, и будем рады сделать ваш визит приятным и комфортным.

Напишите 'Да', и мы свяжемся с вами, чтобы подобрать удобное время и ответить на все вопросы.`;

const defaultReactivationFormData = {
  ignoreActiveReactivation: "0",
  ignorePostBan: true,
  channel: "SMS",
  startDate: "",
  startTime: "",
  intervalWhhenLetSending: ["", ""],
  target: "",
  trackDuring: 30,
  finishAfterNextMailing: false,
};

const reactivationModule: Module<any, any> = {
  namespaced: true,
  state: {
    reactivationCreateStep: 0,
    isReactivationIncludeNotification: true,

    reactivationTestNumber: "",
    reactivationTemplate: defaultReactivationTemplate,

    createReactivationFromData: { ...defaultReactivationFormData },

    currentReactivationClientCount: 0,
    currentReactivationClientCountLoading:false,

    reactivationTestLoading: false,
    reactivationTestStatus: "",

    reactivationStatuses: [
      {
        id: null,
        description: "Все",
      },
    ],
    getReactivationLoading: false,
    reactivationList: null,
    currentReactivationsPage: 1,
    currentReactivationFilterStatusId: null,
    totalReactivations: 0,

    clientByCurrentReactivation: null,
    currentPageClientsByReact: 1,
    currentOpenReativationId: null,
    loadingClientByCurrentReactivation: false,
  },
  getters: {
    getNewReactivationDatetime(state) {
      const reactivationFormData = state.createReactivationFromData;
      if (reactivationFormData.startDate && reactivationFormData.startTime) {
        return `${
          reactivationFormData.startTime
        } - ${reactivationFormData.startDate.split("-").reverse().join(".")} `;
      } else {
        return null;
      }
    },
  },
  mutations: {
    updateReactivationTemplate(state, newTemplate) {
      state.reactivationTemplate = newTemplate;
    },
    setReactivationTestNumber(state, newTel) {
      state.reactivationTestNumber = newTel;
    },
    parseGoalsReactivations(state, goals: any) {
      state.reactivationList.forEach((reactivation: any) => {
        const currentGoal = goals.find(
          (i: any) => i.mailing_id === reactivation.id
        );
        if (currentGoal) {
          reactivation.goal = currentGoal;
        }
      });
    },
    resetCreateReactivation(state) {
      state.reactivationTemplate = defaultReactivationTemplate;
      state.createReactivationFromData = { ...defaultReactivationFormData };
    },
    setCurrentReactivationStatus(state, status_id: number | null) {
      state.currentReactivationFilterStatusId = status_id;
      state.currentReactivationsPage = 1
    },
  },
  actions: {
    async createReactivationTest(context) {
      context.state.reactivationTestLoading = true;
      const data = {
        salon_id: context.rootState.salon_id,
        phone: context.state.reactivationTestNumber,
        channel: context.state.createReactivationFromData.channel,
        text: context.state.reactivationTemplate,
      };
      return await axios({
        method: "POST",
        url: " https://rfm.botzavod.com/api/send-test-mailing",
        data: data,
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((data: AxiosResponse) => {
        context.state.reactivationTestLoading = false;
        console.log(data.data);
        context.state.reactivationTestStatus =
          "Тестовое сообщение успешно отправлено";
      });
    },
    async createReactivation(context) {
      context.commit("setMainLoader", true, { root: true });
      const currentReactivationClientCount =
        context.state.currentReactivationClientCount;
      const reactivationFormData = context.state.createReactivationFromData;
      const currentSelectSegment =
        context.rootState.segments.currentSelectSegment;
      const reactivationsName = context.getters.getNewReactivationDatetime;
      "| " +
        "Сегмент " +
        currentSelectSegment.name +
        " | " +
        currentReactivationClientCount +
        "Клиентов";
      const startDatetime =
        reactivationFormData.startDate +
        " " +
        reactivationFormData.startTime +
        ":00";
      const ignoreStop = reactivationFormData.ignorePostBan ? 1 : 0;
      const trackDuring = +reactivationFormData.trackDuring;
      const finishAfterNextMailing = reactivationFormData.finishAfterNextMailing
        ? 1
        : 0;

      const ignoreActiveReactivation =
        +reactivationFormData.ignoreActiveReactivation;
      const data = {
        salon_id: context.rootState.salon_id,
        name: reactivationsName,
        segment_id: `${currentSelectSegment.index}`,
        channel: reactivationFormData.channel,
        start_date: startDatetime,
        text: context.state.reactivationTemplate,
        ignore_stop: ignoreStop,
        finish_after_days: trackDuring,
        finish_after_next_mailing: finishAfterNextMailing,
        ignore_active_reactivation: ignoreActiveReactivation,
      };

      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/set-mailing",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        data: data,
      }).then((res: AxiosResponse) => {
        console.log(res.data);
        context.commit("setMainLoader", false, { root: true });
        context.state.reactivationCreateStep = 0;
        context.rootState.segments.showSegmentActions = false;
        context.commit("resetCreateReactivation");
        window.scrollTo(0, 0);
        context.dispatch("getReactivations");
      });
    },
    async getReactivations(context, page) {
      context.state.getReactivationLoading = true
      context.state.currentReactivationsPage = page;
      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-company-mailing",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
          page: page,
          status_id: context.state.currentReactivationFilterStatusId,
        },
      })
        .then((res: AxiosResponse) => {
          context.state.reactivationList = res.data.company_mailings;
          context.state.totalReactivations = res.data.total_mailing_pages * 10;

          context.dispatch("getGoalsReactivations");
          setTimeout(() => {
            context.state.getReactivationLoading = false
          }, 400);
        })
        .catch((error: any) => {
          console.log(error);
          setTimeout(() => {
            context.state.getReactivationLoading = false
          }, 400);
          if (error?.response?.data?.description === "Рассылки не найдены") {
            context.state.reactivationList = [];
            context.state.totalReactivations = 0
          }
        });
    },
    async getGoalsReactivations(context) {
      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-company-mailing-goals",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
        },
      })
        .then((res: AxiosResponse) => {
          context.commit("parseGoalsReactivations", res.data.company_mailings);
          context.commit("setMainLoader", false, { root: true });
        })
        .catch((error: any) => {
          context.commit("setMainLoader", false, { root: true });
          console.log(error);
        });
    },
    async getReactivationCount(context) {
      context.state.currentReactivationClientCountLoading = true
      const reactivationFormData = context.state.createReactivationFromData;
      const currentSelectSegment =
        context.rootState.segments.currentSelectSegment;
      const ignoreStop = reactivationFormData.ignorePostBan ? 1 : 0;
      const trackDuring = +reactivationFormData.trackDuring;

      const ignoreActiveReactivation =
        +reactivationFormData.ignoreActiveReactivation;
      const data = {
        salon_id: context.rootState.salon_id,
        segment_id: `${currentSelectSegment.index}`,
        channel: reactivationFormData.channel,
        ignore_stop: ignoreStop,
        finish_after_days: trackDuring,
        ignore_active_reactivation: ignoreActiveReactivation,
      };
      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/count-mailing-clients",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        data: data,
      })
        .then((res) => {
          console.log(res);

          if (res.data.total_clients) {
            context.state.currentReactivationClientCount =
              res.data.total_clients;
          }
          context.state.currentReactivationClientCountLoading = false
        })
        .catch((err) => {
          console.log(err);
          context.state.currentReactivationClientCount = 0;
          context.state.currentReactivationClientCountLoading = false
        });
    },
    async stopWatchingReactivation(context, mailingId) {
      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/finish-mailing-monitoring",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        data: {
          salon_id: context.rootState.salon_id,
          mailing_id: mailingId,
        },
      })
        .then((data) => {
          console.log(data);
          context.dispatch("getReactivations");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getReactivationClientsByPage(context, { reactivationId, page }) {
      context.state.loadingClientByCurrentReactivation = true;
      context.state.currentPageClientsByReact = page;
      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-mailing-clients",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
          mailing_id: reactivationId,
          page: page,
        },
      }).then((data) => {
        context.state.clientByCurrentReactivation = data.data.clients;
        context.state.loadingClientByCurrentReactivation = false;
      });
    },

    async getReactivationStatuses(context) {
      return await axios({
        url: "https://rfm.botzavod.com/api/get-mailing-statuses",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
        },
      }).then((data) => {
        console.log(data);
        if (data.data.statuses) {
          data.data.statuses.unshift({
            description: "Все",
            id: null,
          });
          context.state.reactivationStatuses = data.data.statuses;
        }
      });
    },
  },
};

export default reactivationModule;
