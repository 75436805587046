<template>
  <div
    v-if="segment"
    class="base-segment _base-positions"
    :class="segment.border_color ? '_hoverBorderPlus' : ''"
    :style="segmentStyles"
  >
    <div
      class="base-segment__number _number"
      v-if="segment.index"
      :style="`border-color:${segment.text_color}`"
      v-html="getIndexText(segment.index)"
    ></div>
    <img
      class="base-segment__smile"
      v-if="segment.smileLogo"
      :src="require(`@/assets/images/segments-smiles/${segment.smileLogo}`)"
      alt=":)"
    />
    <img
      v-if="segment.smileAnimation"
      class="base-segment__animation"
      :src="
        require(`@/assets/images/segments-smiles/${segment.smileAnimation}`)
      "
      alt=":)"
    />
    <div class="base-segment__name" v-html="segment.name"></div>
    <div
      class="base-segment__stats"
      :class="{ 'loading-text': isTableLoading }"
    >
      <span class="fw-600" v-html="totalClientStr"> </span> —
      {{ totalPercentStr }}
    </div>
    <div class="base-segment__name" v-html="currentTwoParamName"></div>
    <div
      class="base-segment__check"
      :class="{ 'loading-text': isTableLoading }"
      v-html="currentTwoParamValue"
    ></div>

    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { ISegment } from "@/types/typesOfApi";
import { computed } from "vue";
import { useStore } from "vuex";

type Props = {
  segment: ISegment;
};
/* eslint-disable-next-line */
const props = withDefaults(defineProps<Props>(), {
settingsBtn: false,
})

const store = useStore();

function openSettings() {
  store.state.segments.showSegmentSettings = true
}

const predictedIncomeShow = computed(() => {
  return (
    props.segment.predicted_income &&
    store.state.segments.predictedIncomeShow === "on"
  );
});

function getIndexText(index) {
  const modIds = [123, 121314]

  if (modIds.includes(index)) {
    return index === 123 ? '+' : '₽'
  } else {
    return index
  }
}

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const segment = computed(() => props.segment);

function addSpacesForNumber(str) {
  if (!isNaN(str)) {
    return str.toLocaleString();
  } else {
    return "0";
  }
}

const totla_percent = computed(() => {
  const num = +segment.value.total_clients_percent;

  return num.toFixed(1);
});

const totalClientStr = computed(() =>
  addSpacesForNumber(segment.value.total_clients)
);
const totalPercentStr = computed(
  () => addSpacesForNumber(totla_percent.value) + "%"
);

const currentTwoParamName = computed(() => {
  if (predictedIncomeShow.value) {
    return "Потенциал выручки";
  } else {
    return segment.value.average_check ? "Средний чек" : "На сумму";
  }
});
const currentTwoParamValue = computed(() => {
  if (predictedIncomeShow.value) {
    return (
      addSpacesForNumber(Math.trunc(+segment.value.predicted_income)) + " ₽"
    );
  } else {
    if (segment.value.average_check) {
      return (
        addSpacesForNumber(Math.trunc(+segment.value.average_check)) + " ₽"
      );
    } else {
      return addSpacesForNumber(Math.trunc(+segment.value.check)) + " ₽";
    }
  }
});

const segmentStyles = computed(() => {
  const bgColor = segment.value.background_color;
  const borderColor = segment.value.border_color;
  return `
  background-color:${borderColor ? "#fff" : bgColor}; 
  border: solid 2px ${borderColor}; 
  padding:${borderColor ? "20px" : "22px"}`;
});
</script>

<style lang="scss">
$segment-border-raduis: 12px;
$inner-border-raduis: 11px;

.base-segment {
  padding: 22px;
  font-size: 15px;
  display: grid;
  position: relative;

  &__smile {
    width: 26px;
    height: 26px;
  }

  &__animation {
    display: none;
  }

  &__number {
    font-size: 13px;
    width: 28px;
    height: 28px;
    border: solid #1e2022 2.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 500;
  }

  &._all-awaiting {
    background: #8febcb;
    border-radius: 12px;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;
    padding-left: 20px !important;
    max-height: 91px;

    .fw-600 {
      display: flex;
      justify-content: space-between;
    }
  }
  &__name,
  &__stats,
  &__check {
    grid-column: span 2;
  }

  &__check,
  &__stats {
    font-size: 18px;
  }
  &__check {
    font-weight: 500;
  }
  &__price {
    font-size: 20px;
  }

  &__stats {
    margin-bottom: 8px;
  }

  &._base-positions {
    display: grid;

    grid-template-columns: 28px auto;
    grid-template-rows: 35px auto auto auto auto 1fr;

    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }

  // &._hover:hover {
  //   border-color: #9949ff !important;
  //   padding: 22px;
  //   cursor: pointer;
  // }

  &._border-top-left-radius {
    border-top-left-radius: $segment-border-raduis;
    &:before {
      border-top-left-radius: $inner-border-raduis;
    }
  }
  &._border-top-right-radius {
    border-top-right-radius: $segment-border-raduis;
    &:before {
      border-top-right-radius: $inner-border-raduis;
    }
  }
  &._border-bottom-left-radius {
    border-bottom-left-radius: $segment-border-raduis;
    &:before {
      border-bottom-left-radius: $inner-border-raduis;
    }
  }
  &._border-bottom-right-radius {
    border-bottom-right-radius: $segment-border-raduis;
    &:before {
      border-bottom-right-radius: $inner-border-raduis;
    }
  }
  &._border-radius {
    border-radius: $segment-border-raduis;
    &:before {
      border-radius: $inner-border-raduis;
    }
  }
  &._border-top-radius {
    border-top-left-radius: $segment-border-raduis;
    border-top-right-radius: $segment-border-raduis;
    &:before {
      border-top-left-radius: $inner-border-raduis;
      border-top-right-radius: $inner-border-raduis;
    }
  }
  &._border-bottom-radius {
    border-bottom-left-radius: $segment-border-raduis;
    border-bottom-right-radius: $segment-border-raduis;
    &:before {
      border-bottom-left-radius: $inner-border-raduis;
      border-bottom-right-radius: $inner-border-raduis;
    }
  }
  &._border-radius {
    border-radius: $segment-border-raduis;
    &:before {
      border-radius: $inner-border-raduis;
    }
  }
  &._hover {
    cursor: pointer;
  }
  &._hover::before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    box-sizing: border-box;
    transition: border-color 0.08s;
  }

  &._hover._hoverBorderPlus:before {
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
  }

  &._hover:hover::before {
    border-color: #9949ff;
  }

  &._hover:hover {
    .base-segment__smile {
      display: none;
    }
    .base-segment__animation {
      width: 32px;
      height: 32px;
      display: block;
      transform: translate(-3px, -3px);
    }
  }
}
</style>
