<template>
  <div
    class="clients-pagination-list"
    :class="clientPageLoading ? '_loading' : ''"
  >
    <div class="pagination-tab" v-if="paginationTab">
      <button
        class="pagination-tab__item"
        v-for="tabItem in paginationTab"
        :key="tabItem.id"
        :class="{ _active: tabItem.id === currentTab }"
        @click="handlePaginationTab(tabItem)"
      >
        {{ tabItem.text }}
      </button>
    </div>
    <paginationBlock
      class="mb-20px"
      @getPage="(pageNumber) => getPage(pageNumber)"
      :totalItems="total_clients"
      :itemsPerPage="25"
      :defaultPage="defaultPaginationPage"
      v-if="!tabContentShow"
    >
      <template v-slot:elements>
        <zebraTable
          :headerElements="tabSettings.names"
          :gridColumns="tabSettings.grid"
          :elementsOrder="tabSettings.order"
          :idFieldName="'client_id'"
          :elements="clientsList"
          :firstColHandleClick="true"
          @fisrtElementWasClicked="(client) => goToClient(client)"
          :titles="['Открыть карточку клиента']"
        ></zebraTable>
      </template>
    </paginationBlock>
    <div v-else>
      <slot name="tabContent"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import zebraTable from "@/components/UI/bloks/zebraTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import { computed, ref, onMounted, watch, onUnmounted } from "vue";

import goToClientPageYclients from "@/functions/goToClientPageYclients";

const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const tableHeader = ["Телефон", "Имя", "Давность", "Визитов"];
const tableHeaderElementsOrder = ["phone", "name", "day_count", "visit_count"];
const tabGridColumns = "3fr 3fr 3fr 4fr";

const blakcListTableHeader = [...tableHeader, "Сегмент"];
const blakcListElementsOrder = [...tableHeaderElementsOrder, "segmentName"];
const blakcListGridColumns = "3fr 3fr 3fr 2fr 2.4fr;";

const tabSettings = computed(() => {
  let settings = {
    names: tableHeader,
    order: tableHeaderElementsOrder,
    grid: tabGridColumns,
  };
  if (currentSegment.value.index === 16) {
    settings = {
      names: blakcListTableHeader,
      order: blakcListElementsOrder,
      grid: blakcListGridColumns,
    };
  }

  return settings;
});

const emit = defineEmits(["updateTab"]);

type tabItem = {
  text: string;
  id: number;
};

type props = {
  paginationTab?: tabItem[];
  currentTab?: number;
  tabContentShow: boolean;
};

/* eslint-disable-next-line */
const props = withDefaults(defineProps<props>(), {
  tabContentShow: false,
});

function handlePaginationTab(tabItem) {
  emit("updateTab", tabItem);
}

const currentClientPage = computed(
  () => store.state.segments.clients.currentClientPage
);

const clientPageLoading = computed(
  () => store.state.segments.clients.clientPageLoading
);

const defaultPaginationPage = computed({
  get: () => store.state.segments.clients.defaultRecomendationPageClients,
  set: (newVal) =>
    (store.state.segments.clients.defaultRecomendationPageClients = newVal),
});

const total_clients = computed(() => +currentClientPage.value.total);

const clientsList = computed(() => currentClientPage.value.segment_clients);

const currentPage = ref(1);

function getPage(pageNumber) {
  currentPage.value = pageNumber;
  const elements = Array.from(document.querySelectorAll('[id^="client_id_"]'));
  if (elements) {
    elements.forEach((i) => (i.style.outline = ""));
  }

  store
    .dispatch("segments/clients/getClientsForSegmentByPage", {
      segmentIndex: currentIndex.value,
      page: pageNumber,
    })
    .then(() => {
      setTimeout(() => {
        const id = store.state.segments.clients.scrollToClientId;
        const elements = Array.from(
          document.querySelectorAll('[id^="client_id_"]')
        );
        elements.forEach((i) => (i.style.outline = ""));
        if (id) {
          const element = document.querySelector(`#${id}`);
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          element.style.outline = "blue 1px solid";
          store.state.segments.clients.scrollToClientId = "";
          store.state.segments.clients.defaultPaginationPage = 1;
        }
      }, 200);
    });
}

const currentIndex = computed(() =>
  currentSegment.value.key === "awaiting_segments"
    ? 121314
    : currentSegment.value.index
);

function goToClient(client) {
  const salonId = store.state.salon_id;
  const number = client.phone;

  goToClientPageYclients(salonId, number);
}

onUnmounted(() => {
  defaultPaginationPage.value = 1;
});
</script>

<style lang="scss" scoped>
.clients-pagination-list {
  &._loading {
    filter: blur(5px);
  }
}

.pagination-tab {
  display: flex;
  font-size: 14px;
  gap: 11px;

  &__item {
    min-width: 237px;
    background: #f0f3f8;
    border-radius: 12px 12px 0 0;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
    position: relative;
    bottom: -10px;
    color: #6d6d6d;
    padding: 16px 20px;

    &:hover,
    &._active {
      background: #f6f9fc;
      bottom: 0;
      color: #000;
    }
  }
}
</style>
