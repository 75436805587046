import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "page-sections-header__top" }
const _hoisted_2 = { class: "sections-info" }
const _hoisted_3 = { class: "page-sections-header__btns" }
const _hoisted_4 = { class: "page-sections-header__content" }

import { computed } from "vue";

type typeProps = {
  sectionsColor: Array<string>;
  closeBtnClass: string;
  textColor: string;
};




export default /*@__PURE__*/_defineComponent({
  __name: 'pageWithSectionsHeader',
  props: {
    sectionsColor: { default: () => ["#fff", "#fff", "#fff"] },
    closeBtnClass: {},
    textColor: { default: "#000" }
  },
  setup(__props: any) {

const props = __props;

const transTextColor = computed(() => addTransparency(props.textColor))

function addTransparency(color) {
  // Проверяем, является ли цвет в формате HEX
  if (color.startsWith("#")) {
    // Преобразуем HEX в RGB
    let r, g, b;
    if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16);
      g = parseInt(color[2] + color[2], 16);
      b = parseInt(color[3] + color[3], 16);
    } else if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else {
      throw new Error("pageWithSectionsHeader: Неправильный формат HEX цвета");
    }
    // Возвращаем цвет в формате rgba с прозрачностью 45%
    return `rgba(${r}, ${g}, ${b}, 0.45)`;
  }

  // Проверяем, является ли цвет в формате rgba
  if (color.startsWith("rgba")) {
    // Извлекаем значения r, g, b
    const rgbaValues = color.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]*)\)/
    );
    if (rgbaValues) {
      const r = rgbaValues[1];
      const g = rgbaValues[2];
      const b = rgbaValues[3];
      // Возвращаем цвет с новой прозрачностью 45%
      return `rgba(${r}, ${g}, ${b}, 0.45)`;
    }
  }

  throw new Error("pageWithSectionsHeader: Неподдерживаемый формат цвета");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "page-sections-header",
    style: _normalizeStyle(`--page-sections-bg: ${_ctx.sectionsColor[0]}; --text-top-color: ${
      _ctx.textColor || '#000'
    };  --trans-text-color: ${transTextColor.value};`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "top"),
      (_ctx.closeBtnClass)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.closeBtnClass),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, " Закрыть без изменений ", 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("header", {
      class: _normalizeClass(["sections-header", { '_with-tab': _ctx.headerTab }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[0]}`)
        }, [
          _renderSlot(_ctx.$slots, "header1")
        ], 4),
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[1]}`)
        }, [
          _renderSlot(_ctx.$slots, "header2")
        ], 4),
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[2]}`)
        }, [
          _renderSlot(_ctx.$slots, "header3")
        ], 4)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "btns")
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ], 4))
}
}

})