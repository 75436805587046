<template>
  <div v-show="!showSegmentActions || (showSegmentActions && showSegmentSettings)">
    <div class="app-header">
      <lastUpdateTimer
        :bgTimer="bgUpdateTimer"
        :interval="60000"
        :updateFunction="updateTableFunction"
        :isTimerRunning="stateUpdateTimer"
        :defaultStr="'Загрузка'"
        class="mb-10px"
      />
      <searchClientByPhone
        @openSegment="(index) => openSegmentByIndex(index)"
      ></searchClientByPhone>
    </div>

    <div class="segments-table" v-if="$store.state.segments.modifySegments">
      <segmentTableHeader
        @setCurrentSegment="(segment) => setCurrentSegment(segment, segment)"
      ></segmentTableHeader>
      <awaitingSegments
        @setAwaitSegment="(segment) => setAwaitSegment(segment)"
      ></awaitingSegments>
      <dynamicSegments
        @setCurrentSegment="(segment) => setCurrentSegment(segment, segment)"
      ></dynamicSegments>
    </div>
  </div>

  <segmentsMenu v-if="showSegmentActions"></segmentsMenu>

  <a
    v-if="!showSegmentActions"
    class="btn _opacity"
    href="https://b24-z5t4c9.bitrix24site.ru/crm_form_fnes1/"
    target="_blank"
    >заявка на доработку</a
  >
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import segmentsMenu from "./segmentsMenu.vue";

import segmentTableHeader from "@/components/main-page/segments/segmentTableItems/segmentTableHeader.vue";
import awaitingSegments from "@/components/main-page/segments/segmentTableItems/awaitingSegments.vue";
import dynamicSegments from "@/components/main-page/segments/segmentTableItems/dynamicSegments.vue";

import lastUpdateTimer from "@/components/UI/bloks/lastUpdateTimer.vue";
import searchClientByPhone from "./searchClientByPhone.vue";
import { computed, ref } from "vue";
import { errorText } from "@/store/modules/segments/segmentsModuleTypes";
import { AxiosError } from "axios";
import getCurrentUpdateDateStr from "@/assets/getCurrentUpdateDateStr";

import getSegmentByIndex from '@/functions/getSegmentByIndex'

const store = useStore();

const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const showSegmentSettings = computed(() => store.state.segments.showSegmentSettings)

const showSegmentActions = computed({
  get: () => store.state.segments.showSegmentActions,
  set: (newValue) => (store.state.segments.showSegmentActions = newValue),
});


function openSegmentByIndex(index) {
  if (!isTableLoading.value) {
    const modifySegments = store.state.segments.modifySegments;
    
    const segment = getSegmentByIndex(modifySegments, index)

    if (segment) {
      const currentSegment = modifySegments.awaitingSegments.includes(segment)
        ? modifySegments.awaitSegment
        : segment;
      store.commit("segments/setCurrentSegment", {
        current: currentSegment,
        add: segment,
      });
      showSegmentActions.value = true;
      window.scrollTo(0, 0);
      store.commit("reactivation/resetCreateReactivation");
    } else {
      console.error(`Segment with index ${index} not found.`);
    }
  }
}

function setCurrentSegment(segment, add) {
  if (!isTableLoading.value) {
    store.commit("segments/setCurrentSegment", {
      current: segment,
      add: add,
    });
    
    showSegmentActions.value = true;
    window.scrollTo(0, 0);
    store.commit("reactivation/resetCreateReactivation");
  }
}
const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

function setAwaitSegment(segment) {
  setCurrentSegment(awaitSegment.value, segment);
}

let stateUpdateTimer = computed(() => !!store.state.auth.bearerToken);
let bgUpdateTimer = ref("#fbcd00");

function updateTableFunction() {
  return store
    .dispatch("segments/getSegmentsTable")
    .then(() => {
      bgUpdateTimer.value = "#fbcd00";
      return getCurrentUpdateDateStr();
    })
    .catch((error: AxiosError<{ description: errorText }>) => {
      console.log(error);
      bgUpdateTimer.value = "#FF4141";
      const errorDescr = error?.response?.data?.description;
      if (errorDescr) {
        return errorDescr;
      } else {
        return "Произошла ошибка - обратитесь в поддержку ";
      }
    });
}
</script>

<style lang="scss" scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 4px;
}

.segments-table {
  width: 100%;
  max-width: 1600px;
  display: grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 266px minmax(auto, 1450px);
  row-gap: 23px;
}

.negative-segments {
  grid-column: span 2;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(auto, 271px) minmax(auto, 374px);
  grid-column-gap: 36px;

  .base-segment {
    border-radius: 12px;
  }
}
.btn {
  width: 100%;
  margin-top: 101px;
  align-self: flex-start;
}
</style>
