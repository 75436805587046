import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "dynamic-segments" }
const _hoisted_2 = { class: "dynamic-segments__table" }
const _hoisted_3 = { class: "graph-y" }
const _hoisted_4 = { class: "dynamic-segments__list" }
const _hoisted_5 = { class: "dynamic-segments__title fw-600" }
const _hoisted_6 = { class: "graph-x" }

import { computed, ref, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import baseSegment from "@/components/main-page/segments/baseSegment.vue";
import segmentsItem from "@/components/main-page/segments/segmentsItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'dynamicSegments',
  emits: ["setCurrentSegment"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const emit = __emit;

const company_rfm = computed(() => store.state.segments.company_rfm);
const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const segmentsArray = computed(
  () => store.state.segments.modifySegments.dynamicSegments
);
const outflow = computed(() => store.state.segments.modifySegments.outflow);
const hadVisits = computed(() => store.state.segments.modifySegments.hadVisits);
const black_list = computed(
  () => store.state.segments.modifySegments.black_list
);

const graphX = computed(() => {
  const levelsArr = [
    +company_rfm.value.r1_level,
    +company_rfm.value.r2_level,
    +company_rfm.value.r3_level,
    +company_rfm.value.r4_level,
  ];

  const floorArr = levelsArr.map((i) => Math.floor(i));

  return floorArr;
});
const graphY = ["4+", 3, 2, 1, 0];

const segment6Width = ref(0);
const segment4Width = ref(0);
function updateSegmentWidth() {
  const segment6 = document.querySelector(".segment-6");
  const segment4 = document.querySelector(".segment-4");
  if (segment6) {
    segment6Width.value = segment6.offsetWidth;
  }
  if (segment4) {
    segment4Width.value = segment4.offsetWidth;
  }
}

const isTestFilial = computed(() => store.getters.isTestFilial);

onMounted(() => {
  updateSegmentWidth();
  // Можно добавить слушатель на изменение размера окна, если нужно
  setTimeout(() => {
    updateSegmentWidth();
    window.addEventListener("resize", updateSegmentWidth);
  }, 500);
});
onUpdated(() => {
  updateSegmentWidth();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(graphY, (num) => {
          return _createElementVNode("span", { key: num }, _toDisplayString(num), 1)
        }), 64))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(segmentsArray.value, (segment, index) => {
          return (_openBlock(), _createBlock(segmentsItem, {
            key: segment.id,
            segment: segment,
            class: _normalizeClass(`segment-${index}`),
            onClick: ($event: any) => (emit('setCurrentSegment', segment))
          }, null, 8, ["segment", "class", "onClick"]))
        }), 128)),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "graph-line _0" }, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "graph-line _1" }, null, -1)),
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "graph-line _2" }, null, -1)),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "graph-line _3" }, null, -1))
      ]),
      _createVNode(baseSegment, {
        segment: outflow.value,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('setCurrentSegment', outflow.value))),
        class: _normalizeClass([{ '_hover _border-radius': !isTableLoading.value }, "segment-outflow"])
      }, null, 8, ["segment", "class"]),
      _createElementVNode("div", _hoisted_5, [
        _cache[7] || (_cache[7] = _createTextVNode(" После визита ")),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(graphX.value, (num) => {
            return (_openBlock(), _createElementBlock("span", {
              key: num,
              class: _normalizeClass({ 'loading-text': isTableLoading.value })
            }, _toDisplayString(num), 3))
          }), 128))
        ])
      ]),
      _createVNode(baseSegment, {
        style: _normalizeStyle(`width:${segment6Width.value - 0.3}px`),
        segment: hadVisits.value,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('setCurrentSegment', hadVisits.value))),
        class: _normalizeClass([{ '_hover ': !isTableLoading.value }, "segment-hadVisits _border-radius"])
      }, null, 8, ["style", "segment", "class"]),
      _createVNode(baseSegment, {
        style: `width: 199px;color: #fff; transform: translateX(4px);`,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('setCurrentSegment', black_list.value))),
        segment: black_list.value,
        class: _normalizeClass([{ _hover: !isTableLoading.value }, "segment-backList _border-radius"])
      }, null, 8, ["segment", "class"])
    ])
  ]))
}
}

})