import defaultSegments from "@/assets/defaultSegments";
import {
  ISegmentsModule,
  typeNameSegment,
  TypeSegment,
} from "@/types/segments/segmentsTypes";
import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

import recomednationModule from "./recomednationsModule";
import clientsModule from "./clientsModule";
import settingsModule from './settingsModule'

function calcoutflowPredictedIncome(segment: TypeSegment): number | null {
  if (segment.average_check) {
    return +segment.total_clients * +segment.average_check;
  }
  return null;
}

const segmentsModule: Module<ISegmentsModule, any> = {
  namespaced: true,
  state: {
    predictedIncomeShow: "off",
    company_rfm: null,
    modifySegments: null,
    showSegmentSettings: false,
    showSegmentActions: false,
    currentSettingSegment: null,
    currentSelectSegment: null,
    isTableLoading: true,
    animationFinallySegmentsTable: false,
  },
  mutations: {
    modifyAndSetSegments(state, company_rfm) {
      const expect_segments = company_rfm.expect_segments;
      const modifyDymanicSegments: TypeSegment[] =
        defaultSegments.defaultDymanic.map((segment) => {
          const segmentKey: typeNameSegment | string = segment.key;
          return {
            ...segment,
            ...company_rfm.segments[segmentKey],
            predicted_income: calcoutflowPredictedIncome(
              company_rfm.segments[segmentKey]
            ),
          };
        });

      const modifyAwaitingSegments: TypeSegment[] =
        defaultSegments.defaultAwaiting.map((segment) => {
          const segmentKey: typeNameSegment | string = segment.key;
          return { ...segment, ...expect_segments.segments[segmentKey] };
        });

      const awaitSegment: TypeSegment = { ...defaultSegments.awaitSegment };
      awaitSegment.check = expect_segments.check;
      awaitSegment.total_clients_percent =
        expect_segments.total_expected_clients_percent;
      delete awaitSegment.average_check;
      delete awaitSegment.average_check;
      awaitSegment.total_clients = expect_segments.total_expected_clients;

      const outflow = company_rfm.segments.outflow;

      const modifySegments = {
        outflow: {
          ...outflow,
          ...defaultSegments.outflow,
          key: "outflow",
          predicted_income: calcoutflowPredictedIncome(outflow),
        },
        without_orders: {
          ...defaultSegments.without_orders,
          ...company_rfm.segments.without_orders,
          name: "Клиенты без визитов",
        },
        without_visits: {
          ...defaultSegments.without_visits,
          ...company_rfm.segments.blank_clients_with_orders,
          
        },
        without_taking_orders: {
          ...defaultSegments.without_taking_orders,
          ...company_rfm.segments.blank_clients_without_orders,
        },
        dynamicSegments: modifyDymanicSegments,
        awaitingSegments: modifyAwaitingSegments,
        awaitSegment: awaitSegment,
        hadVisits: {
          average_check: company_rfm.s123_average_check,
          total_clients_percent: company_rfm.s123_percent,
          total_clients: company_rfm.total_s123_clients,
          ...defaultSegments.had_come,

          predicted_income:
            +company_rfm.s123_average_check * +company_rfm.total_s123_clients,
        }, 
        black_list: {
          ...company_rfm.black_list,
          ...defaultSegments.black_list,
          total_clients_percent: company_rfm.black_list?.black_list_percent,
          total_clients: company_rfm.black_list?.total_black_list_clients,
          average_check: company_rfm.black_list?.black_list_average_check,
        },
      };

      state.modifySegments = modifySegments;
      state.company_rfm = company_rfm;
    },
    setCurrentSegment(
      state,
      params: { current: TypeSegment | null; add: TypeSegment | null }
    ) {
      state.currentSettingSegment = params.current;
      state.currentSelectSegment = params.add;
    },
  },
  getters: {
    getRecencies(state) {
      return {
        r1: state.company_rfm?.r1_level,
        r2: state.company_rfm?.r2_level,
        r3: state.company_rfm?.r3_level,
        r4: state.company_rfm?.r4_level,
      };
    },
  },
  actions: {
    async getSegmentsTable(context) {
      const url = `https://rfm.botzavod.com/api/rfm-company`;

      return await axios({
        method: "GET",
        url: url,
        params: {
          salon_id: context.rootState.salon_id,
        },

        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res: AxiosResponse) => {
        console.log(res.data.company_rfm);
        context.commit("modifyAndSetSegments", res.data.company_rfm);
        context.state.isTableLoading = false;
      });
    },
  },
  modules: {
    recomednation: recomednationModule,
    clients: clientsModule,
    settings: settingsModule
  },
};

export default segmentsModule;
