import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "default-accordion__header" }
const _hoisted_2 = { class: "default-accordion__content" }

import UiAccordion from "@/components/UI/ui-accordion.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'default-accordion',
  props: {
  defaultOpen: Boolean,
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(UiAccordion, {
    class: "default-accordion",
    defaultOpen: __props.defaultOpen
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header"),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "default-accordion__toggle" }, null, -1))
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "content")
      ])
    ]),
    _: 3
  }, 8, ["defaultOpen"]))
}
}

})