<template>
  <div class="black-list-settings">
    <default-accordion :defaultOpen="false" class="black-list-settings__accordion segment-settings__accordion">
      <template v-slot:header> <span class="fs-14px">Как работает сегмент «Чёрный список»</span> </template>
      <template v-slot:content>
        <div class="segment-settings__desc">
          <p class="font-ital fw-500">
            Чёрный список – это специальный сегмент, куда вы можете переместить
            любого клиента из вашей базы, назначив ему соответствующую категорию
            в системе YCLIENTS.
          </p>
          <br>

          <p class="fw-600">Добавление клиента в Чёрный список</p>
          <br>

          <p class="font-ital">
            Чтобы отправить клиента в этот сегмент, необходимо:
          </p>
          <br>

          <ol class="default-ol fs-14px">
            <li>
              В системе YCLIENTS создайте и назначьте клиенту категорию «Чёрный
              список» или любую другую.
            </li>
            <li>
              Настройте Сегмент “Чёрный список” в сервисе Сегменты, выбрав
              Категории для исключения клиентов из рассылки.
            </li>
            <li>
              Все клиенты помеченные выбранной категорией перейдут в Сегмент
              Чёрный список.
            </li>
            <li>
              Как только клиент будет помечен в системе YCLIENTS категорией он
              будет исключен автоматически и сразу из активных сегментов в
              сервисе Сегменты.
            </li>
          </ol>
          <br>

          <p class="fw-600">Удаление клиента из Чёрного списка</p>
          <br>

          <p class="font-ital">
            Если вы хотите вернуть клиента в рабочие сегменты:
          </p>
          <br>

          <ol class="default-ol fs-14px">
            <li>Откройте клиентскую базу в YCLIENTS.</li>
            <li>
              Настройте Сегмент “Чёрный список” в сервисе Сегменты, выбрав
              Категории для исключения клиентов из рассылки.
            </li>
            <li>Удалите у него категорию «Чёрный список»</li>
          </ol>
          <br>

          <p>
            После этого клиент автоматически и мгновенно вернётся в свой
            исходный сегмент в сервисе Сегменты.
          </p>
          <br>

          <p class="fw-600">Функции чёрного списка</p>
          <br>

          <p class="font-ital">При добавлении клиента в Чёрный список:</p>
          <br>
          <ul>
            <li>
              ✅ Клиент исключается из рабочей области активных сегментов.
            </li>
            <li>
              ✅ Число активных клиентов уменьшается, позволяя видеть реальное
              количество работающих клиентов.
            </li>
            <li>✅ Клиент не получает уведомления из сервиса Сегменты.</li>
            <li>
              ✅ Клиент не исключается из расчёта его текущих сегментов – в
              отчётах видно, в каких сегментах он мог бы находиться на текущий
              день.
            </li>
          </ul>
          <br>

          Таким образом, Чёрный список позволяет гибко управлять клиентской
          базой и исключать неактуальных клиентов, сохраняя при этом полную
          аналитику их поведения.
        </div>
      </template>
    </default-accordion>

    <div class="select-categories">
      <input
        ref="categoryInput"
        type="text"
        class="select-categories__search"
        v-model="searchQuery"
        @focus="showDropdown = true"
        @blur="hideDropdown"
        @input="filterCategories"
        placeholder="Добавить категорию клиентов из YCLIENTS"
      />
      <ul
        v-if="showDropdown && filteredCategories"
        class="select-categories__dropdown"
      >
        <li
          v-for="category in filteredCategories"
          :key="category.category_id"
          @mousedown.prevent="toggleCategory(category)"
          :class="{ selected: isSelected(category) }"
        >
          {{ category.title }}
        </li>
      </ul>
      <ul v-else-if="showDropdown" class="select-categories__dropdown">
        <li>Категории не найдены</li>
      </ul>
      <div class="select-categories__list">
        <h4 class="fw-600 select-categories__title">Выбранные категории:</h4>

        <ul v-if="selectedCategories.length > 0">
          <li
            v-for="category in selectedCategories"
            :key="category.category_id"
          >
            {{ category.title }}
            <button @click="removeCategory(category)">Удалить</button>
          </li>
        </ul>
        <ul v-else>
          <li>Нет выбранных категорий</li>
        </ul>
      </div>
    </div>

    <div class="black-list-settings__btns">
      <button class="btn w-100" @click="saveSettings">Сохранить</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import defaultAccordion from "@/components/UI/default-accordion.vue";
import { ref, computed, onMounted, watch } from "vue";

const store = useStore();

// Получаем категории из Vuex
const categories = computed(() => store.state.segments.settings.allCategories);

const searchQuery = ref("");
const filteredCategories = ref([]);
const selectedCategories = computed({
  get: () => store.state.segments.settings.selectedCategories,
  set: (newValue) => {
    store.state.segments.settings.selectedCategories = newValue;
  },
});
const showDropdown = ref(false);
const categoryInput = ref(null);

const filterCategories = () => {
  const query = searchQuery.value.toLowerCase();
  filteredCategories.value = categories.value.filter((category) =>
    category.title.toLowerCase().includes(query)
  );
};

const toggleCategory = (category) => {
  if (isSelected(category)) {
    removeCategory(category);
  } else {
    selectedCategories.value.push(category);
  }
  searchQuery.value = ""; // Сброс поиска после выбора
  filterCategories(); // Обновление списка категорий
  categoryInput.value.blur(); // Снять фокус с поля ввода
};

const removeCategory = (category) => {
  selectedCategories.value = selectedCategories.value.filter(
    (selected) => selected.category_id !== category.category_id
  );
};

const isSelected = (category) => {
  return selectedCategories.value.some(
    (selected) => selected.category_id === category.category_id
  );
};

const hideDropdown = () => {
  setTimeout(() => {
    showDropdown.value = false;
  }, 100);
};

// Загружаем категории при монтировании компонента
onMounted(() => {
  store.dispatch("segments/settings/getClientsCategories").then(() => {
    filteredCategories.value = categories.value;

    store
      .dispatch("segments/settings/getPostedClientsCategories")
  });
});

const isSettingsLoading = computed(
  () => store.state.segments.settings.settingsLoader
);

function close() {
  store.state.segments.showSegmentSettings = false;
  store.state.segments.showSegmentActions = false;
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}
function saveSettings() {
  store.dispatch("segments/settings/postClientsCategories")
}
</script>

<style lang="scss" scoped>
.black-list-settings {
  min-height: 450px;
  color: #000;
  display: flex;
  flex-direction: column;


  &__btns {
    display: flex;
    justify-content: space-between;
  }
}
.segment-settings {
  &__accordion {
    margin-bottom: 35px;
    background: #fff;
  }
  &__desc {
    line-height: 1.4;
  }
}

.select-categories {
  flex-grow: 1;
  position: relative;
  height: 100%;
  margin-bottom: 50px;

  &__title {
    border-bottom: solid 1px #e3e3e3;
    padding-bottom: 10px;
  }

  &__search {
    width: 100%;
    font-size: 18px;
    padding: 15px;
    border-radius: 11px;
    margin-bottom: 25px;
    min-height: 54px;

    &:focus {
      border-radius: 11px 11px 0 0;
    }
  }

  &__dropdown {
    position: absolute;
    top: 50px;
    width: 100%;
    overflow: auto;
    max-height: 310px;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 1000;

    li {
      padding: 10px 5px;
      cursor: pointer;
      display: flex;
      align-items: center;

      justify-content: space-between;

      &:hover {
        background: #dfdfdf;
      }
    }
  }

  &__list {
    margin-top: 20px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 32px;
      border-bottom: solid 1px #e3e3e3;
    }

    button {
      margin-left: 10px;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .selected {
    background-color: #f0f0f0;
  }
}
</style>
