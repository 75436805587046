import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, isRef as _isRef, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "reactivation-create" }
const _hoisted_2 = { class: "reactivation-create__title" }

import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import reactivationCreateForm from "@/components/reactivation/reactivationCreateForm.vue";
import reactivationTemplate from "@/components/reactivation/reactivationTemplate.vue";
import confirmReactivation from "@/components/reactivation/confirmReactivation.vue";
import getDeclension from "@/assets/getDeclension";
import { computed, onUnmounted, ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationCreate',
  setup(__props) {

const store = useStore();

const reactivationCreateStep = computed(
  () => store.state.reactivation.reactivationCreateStep
);

let isCloseSettingsByUnmounted = ref(true)

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const reactivationDatetime = computed(() => {
  const datetime = store.getters["reactivation/getNewReactivationDatetime"];
  if (datetime) {
    return datetime + " | ";
  } else {
    return "";
  }
});

const createReactivationFromData = computed(
  () => store.state.reactivation.createReactivationFromData
);

watch(reactivationCreateStep, () => window.scrollTo(0,0))

watch(
  (createReactivationFromData) => {
    store.dispatch("reactivation/getReactivationCount");
  },
  { deep: true }
);

const currentReactivationClientCount = computed(() => {
  const count = store.state.reactivation.currentReactivationClientCount;
  return count + ' ' + getDeclension(count, "клиент", "клиента", "клиентов");
});

const rv = ref(false);
const rv1 = ref(false);
const rv2 = ref(false);

onUnmounted(() => {
  if (isCloseSettingsByUnmounted.value) {
    store.state.reactivation.reactivationCreateStep = 0;
    store.state.segments.showSegmentActions = false;
  }

  window.scrollTo(0, 0);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(reactivationDatetime.value) + "Сегмент " + _toDisplayString(activeSegment.value.name) + " | " + _toDisplayString(currentReactivationClientCount.value), 1),
    _withDirectives(_createVNode(reactivationCreateForm, {
      onPrev: _cache[0] || (_cache[0] = ($event: any) => (_isRef(isCloseSettingsByUnmounted) //@ts-ignore
 ? isCloseSettingsByUnmounted.value = false : isCloseSettingsByUnmounted = false))
    }, null, 512), [
      [_vShow, reactivationCreateStep.value === 0]
    ]),
    (reactivationCreateStep.value === 1)
      ? (_openBlock(), _createBlock(reactivationTemplate, { key: 0 }))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(confirmReactivation, {
      onPrev: _cache[1] || (_cache[1] = ($event: any) => (_isRef(isCloseSettingsByUnmounted) //@ts-ignore
 ? isCloseSettingsByUnmounted.value = false : isCloseSettingsByUnmounted = false))
    }, null, 512), [
      [_vShow, reactivationCreateStep.value === 2]
    ])
  ]))
}
}

})