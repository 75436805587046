import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pagination-tab"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 2 }

import { useStore } from "vuex";
import zebraTable from "@/components/UI/bloks/zebraTable.vue";
import paginationBlock from "@/components/UI/bloks/paginationBlock.vue";
import { computed, ref, onMounted, watch, onUnmounted } from "vue";

import goToClientPageYclients from "@/functions/goToClientPageYclients";

const tabGridColumns = "3fr 3fr 3fr 4fr";

const blakcListGridColumns = "3fr 3fr 3fr 2fr 2.4fr;";

type tabItem = {
  text: string;
  id: number;
};

type props = {
  paginationTab?: tabItem[];
  currentTab?: number;
  tabContentShow: boolean;
};

/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'segmentClients',
  props: {
    paginationTab: {},
    currentTab: {},
    tabContentShow: { type: Boolean, default: false }
  },
  emits: ["updateTab"],
  setup(__props: any, { emit: __emit }) {

const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const tableHeader = ["Телефон", "Имя", "Давность", "Визитов"];
const tableHeaderElementsOrder = ["phone", "name", "day_count", "visit_count"];
const blakcListTableHeader = [...tableHeader, "Сегмент"];
const blakcListElementsOrder = [...tableHeaderElementsOrder, "segmentName"];
const tabSettings = computed(() => {
  let settings = {
    names: tableHeader,
    order: tableHeaderElementsOrder,
    grid: tabGridColumns,
  };
  if (currentSegment.value.index === 16) {
    settings = {
      names: blakcListTableHeader,
      order: blakcListElementsOrder,
      grid: blakcListGridColumns,
    };
  }

  return settings;
});

const emit = __emit;

const props = __props;

function handlePaginationTab(tabItem) {
  emit("updateTab", tabItem);
}

const currentClientPage = computed(
  () => store.state.segments.clients.currentClientPage
);

const clientPageLoading = computed(
  () => store.state.segments.clients.clientPageLoading
);

const defaultPaginationPage = computed({
  get: () => store.state.segments.clients.defaultRecomendationPageClients,
  set: (newVal) =>
    (store.state.segments.clients.defaultRecomendationPageClients = newVal),
});

const total_clients = computed(() => +currentClientPage.value.total);

const clientsList = computed(() => currentClientPage.value.segment_clients);

const currentPage = ref(1);

function getPage(pageNumber) {
  currentPage.value = pageNumber;
  const elements = Array.from(document.querySelectorAll('[id^="client_id_"]'));
  if (elements) {
    elements.forEach((i) => (i.style.outline = ""));
  }

  store
    .dispatch("segments/clients/getClientsForSegmentByPage", {
      segmentIndex: currentIndex.value,
      page: pageNumber,
    })
    .then(() => {
      setTimeout(() => {
        const id = store.state.segments.clients.scrollToClientId;
        const elements = Array.from(
          document.querySelectorAll('[id^="client_id_"]')
        );
        elements.forEach((i) => (i.style.outline = ""));
        if (id) {
          const element = document.querySelector(`#${id}`);
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          element.style.outline = "blue 1px solid";
          store.state.segments.clients.scrollToClientId = "";
          store.state.segments.clients.defaultPaginationPage = 1;
        }
      }, 200);
    });
}

const currentIndex = computed(() =>
  currentSegment.value.key === "awaiting_segments"
    ? 121314
    : currentSegment.value.index
);

function goToClient(client) {
  const salonId = store.state.salon_id;
  const number = client.phone;

  goToClientPageYclients(salonId, number);
}

onUnmounted(() => {
  defaultPaginationPage.value = 1;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["clients-pagination-list", clientPageLoading.value ? '_loading' : ''])
  }, [
    (_ctx.paginationTab)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginationTab, (tabItem) => {
            return (_openBlock(), _createElementBlock("button", {
              class: _normalizeClass(["pagination-tab__item", { _active: tabItem.id === _ctx.currentTab }]),
              key: tabItem.id,
              onClick: ($event: any) => (handlePaginationTab(tabItem))
            }, _toDisplayString(tabItem.text), 11, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.tabContentShow)
      ? (_openBlock(), _createBlock(paginationBlock, {
          key: 1,
          class: "mb-20px",
          onGetPage: _cache[1] || (_cache[1] = (pageNumber) => getPage(pageNumber)),
          totalItems: total_clients.value,
          itemsPerPage: 25,
          defaultPage: defaultPaginationPage.value
        }, {
          elements: _withCtx(() => [
            _createVNode(zebraTable, {
              headerElements: tabSettings.value.names,
              gridColumns: tabSettings.value.grid,
              elementsOrder: tabSettings.value.order,
              idFieldName: 'client_id',
              elements: clientsList.value,
              firstColHandleClick: true,
              onFisrtElementWasClicked: _cache[0] || (_cache[0] = (client) => goToClient(client)),
              titles: ['Открыть карточку клиента']
            }, null, 8, ["headerElements", "gridColumns", "elementsOrder", "elements"])
          ]),
          _: 1
        }, 8, ["totalItems", "defaultPage"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "tabContent")
        ]))
  ], 2))
}
}

})