import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "recomendation-block__index" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "recomendation-block__name" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "si-tit"
}
const _hoisted_6 = {
  key: 1,
  class: "si-tit"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 3,
  class: "black-categories"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 5,
  class: "si-ps"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "segment-recomendation__settings" }

import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import segmentClients from "@/components/main-page/segments/segmentClients.vue";
import {
  computed,
  onBeforeMount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import blackListSettings from "@/components/main-page/segments/segmentsSettings/blackListSettings.vue";
import { useStore } from "vuex";
import router from "@/router";

import pageWithSectionsHeader from "@/components/UI/bloks/pageWithSectionsHeader.vue";

import goToSegmentInYclients from "@/functions/goToSegmentInYclients";

type typeProps = {
  segment: TypeSegment;
};
/* eslint-disable-next-line */

export default /*@__PURE__*/_defineComponent({
  __name: 'segmentRecomendation',
  props: {
    segment: {}
  },
  setup(__props: any) {

const props = __props;
const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const company_rfm = computed(() => store.state.segments.company_rfm);

const modifySegments = computed(() => store.state.segments.modifySegments);

const isCurrentTarifTest = computed(
  () => store.state.tarif.currentTarif.test_tariff
);

const paramsSemgnet = computed(() => {
  const reslt = {
    param1: null,
    param2: null,
  };
  if (!itsSegmentWithoutOrders.value) {
    if (recencyStr.value) {
      reslt.param1 = createParamsStr("Давность визитов", recencyStr.value);
    }
    if (visitsCountStr.value) {
      reslt.param2 = createParamsStr(
        `Количество визитов`,
        visitsCountStr.value
      );
    }
  }

  return reslt;
});

const itsSegmentWithoutOrders = computed(() =>
  [17, 18, 19].includes(currentSegment.value.index)
);
const itsSegmentBlackList = computed(() => currentSegment.value.index === 16);

const without_orders_tab = [
  {
    text: "Все клиенты",
    id: 0,
    segName: "without_orders",
  },
  {
    text: "Клиенты без визитов",
    id: 1,
    segName: "without_taking_orders",
  },
  {
    text: "Клиенты с визитами / не пришёл",
    id: 2,
    segName: "without_visits",
  },
];

const blackListTab = [
  {
    text: "Черный список",
    id: 0,
    segName: "without_orders",
  },
  {
    text: "Настройки",
    id: 1,
    segName: "without_taking_orders",
  },
];

const tabContentShow = ref(false);

const paginationTab = computed(() => {
  if (itsSegmentWithoutOrders.value) {
    return without_orders_tab;
  } else if (itsSegmentBlackList.value) {
    return blackListTab;
  } else {
    return null;
  }
});

const currentPaginationTab = ref(0);

function updateTab(currentTab) {
  if (itsSegmentWithoutOrders.value) {
    const currentSegments = modifySegments.value[currentTab.segName];
    store.state.segments.currentSettingSegment = currentSegments;
    store.state.segments.currentSelectSegment = currentSegments;
  } else if (itsSegmentBlackList.value) {
    if (currentTab.id === 1) {
      tabContentShow.value = true;
    } else {
      tabContentShow.value = false;
    }
  }

  currentPaginationTab.value = currentTab.id;

  store.dispatch("segments/clients/getClientsForSegmentByPage", {
    segmentIndex: currentSegment.value.index,
    page: 1,
  });
}

onMounted(() => {
  let defaultPaginationTab = 0;
  if (currentSegment.value.index === 17) {
    defaultPaginationTab = 0;
  } else if (currentSegment.value.index === 18) {
    defaultPaginationTab = 1;
  } else if (currentSegment.value.index === 19) {
    defaultPaginationTab = 2;
  }
  currentPaginationTab.value = defaultPaginationTab;

  if (itsSegmentBlackList.value) {
    store.dispatch("segments/settings/getClientsCategories").then(() => {
      store.dispatch("segments/settings/getPostedClientsCategories");
    });
  }
});

const selectedCategories = computed(
  () => store.state.segments.settings.selectedCategories
);

function createParamsStr(string: string, value: string | number): string {
  return `${string} - <span class="fw-500">${value}</span>`;
}

const statisticSemgnet = computed(() => {
  const reslt = [];

  if (totalClientsStr.value) {
    reslt.push(createParamsStr("Клиентов", totalClientsStr.value));
  }
  if (totalClientsPercentStr.value) {
    reslt.push(
      createParamsStr("От общего числа", totalClientsPercentStr.value)
    );
  }
  if (avarageCheckStr.value) {
    reslt.push(createParamsStr("Средний чек", avarageCheckStr.value));
  }

  return reslt;
});

const currentIndex = computed(() => {
  const index = currentSegment.value.index;
  if (typeof index === "number") {
    if (index < 10) {
      return "0" + index;
    } else {
      return index;
    }
  } else {
    return index;
  }
});

function goToClientsInYclients() {
  const recenciesObj = store.getters["segments/getRecencies"];
  const url = goToSegmentInYclients(
    store.state.salon_id,
    props.segment,
    recenciesObj
  );

  console.log(url);
}

function downloadExel() {
  store.commit("tarif/setTariffBanner", true);
  router.push("/tariffs");
}
function goToCreateReactivationWithoutNots() {
  store.state.reactivation.reactivationCreateStep = 2;
  store.state.reactivation.isReactivationIncludeNotification = false;
  goReactivation();
}

function toggleClientPage() {
  recomednationPageClientsShow.value = !recomednationPageClientsShow.value;
}

const recomednationPageClientsShow = computed({
  get: () => store.state.segments.clients.recomedationPageClientsShow,
  set: (newVal) =>
    (store.state.segments.clients.recomedationPageClientsShow = newVal),
});

const total_clients = computed(() => +props.segment.total_clients);

const totalClientsStr = computed(() => total_clients.value.toLocaleString());
const totalClientsPercentStr = computed(
  () => currentSegment.value.total_clients_percent + "%"
);

const currentSegmentBg = computed(() => currentSegment.value.background_color);
const avarageCheckStr = computed(() => {
  const result = Math.trunc(currentSegment.value.average_check);

  if (isNaN(result)) {
    return null;
  } else {
    return result.toLocaleString() + " ₽";
  }
});
const visitsCountStr = computed(() => currentSegment.value.visits || null);

function hideSegments() {
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}

const downloadUrl = computed(() => {
  const salonId = store.state.salon_id;
  const segmentId = currentSegment.value.index;

  const concatenatedString = `${salonId}${segmentId}`;
  const base64Encoded = btoa(concatenatedString);

  console.log(salonId, segmentId, base64Encoded);

  const url = `https://rfm.botzavod.com/api/export-company-segment-clients?salon_id=${salonId}&segment_id=${segmentId}&key=${base64Encoded}`;

  return url;
});

function getRecency(recency) {
  const number = +recency;

  let result = 0;
  if (number === 0) {
    return result;
  }
  if (number === 51.5) {
    result = +company_rfm.value.r1_level;
  }

  if (number === 90) {
    result = +company_rfm.value.r2_level;
  }
  if (number === 125) {
    result = +company_rfm.value.r3_level;
  }
  if (number === 160) {
    result = +company_rfm.value.r4_level;
  }

  return result.toFixed();
}

const recencyStr = computed(() => {
  if (currentSegment.value.index === 10) {
    return `более ${company_rfm.value.r4_level.toFixed()} дней`
  } else {
    return `от ${getRecency(currentSegment.value.min_recency)} до
          ${getRecency(currentSegment.value.max_recency)} дней`;
  }
  
});

function goReactivation() {
  isGoingReactivation.value = true;

  router.push("/reactivation");
  window.scrollTo(0, 0);
}
const isGoingReactivation = ref(false);

onUnmounted(() => {
  store.state.segments.clients.recomedationPageClientsShow = true;
  if (isGoingReactivation.value) {
    store.state.segments.showSegmentActions = true;
  } else {
    store.state.segments.showSegmentActions = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(pageWithSectionsHeader, {
    onClose: hideSegments,
    sectionsColor: [currentSegmentBg.value, currentSegmentBg.value, currentSegmentBg.value],
    class: "segment-recomendation",
    textColor: currentSegment.value.text_color
  }, {
    top: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn _purp w-30",
        onClick: hideSegments
      }, " Закрыть без изменений "),
      (currentSegment.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: toggleClientPage,
            class: _normalizeClass([recomednationPageClientsShow.value ? '_blue' : '_green', "btn w-45"])
          }, " Показать " + _toDisplayString(recomednationPageClientsShow.value ? "рекомендации" : "клиентов"), 3))
        : _createCommentVNode("", true)
    ]),
    header1: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", {
          class: "base-segment__number",
          style: _normalizeStyle(`border-color:${currentSegment.value.text_color}`)
        }, _toDisplayString(currentIndex.value), 5),
        _createElementVNode("img", {
          class: "base-segment__smile",
          src: 
            require('@/assets/images/segments-smiles/' +
              currentSegment.value.smileAnimation)
          ,
          alt: ":)"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(currentSegment.value.name), 1)
    ]),
    header2: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "si-tit" }, "Статистика по сегменту*", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(statisticSemgnet.value, (param) => {
        return (_openBlock(), _createElementBlock("p", {
          innerHTML: param,
          key: param
        }, null, 8, _hoisted_4))
      }), 128)),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "si-ps" }, "*На сейчас", -1))
    ]),
    header3: _withCtx(() => [
      (!itsSegmentBlackList.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Параметры сегмента*"))
        : (_openBlock(), _createElementBlock("p", _hoisted_6, "Исключение на основе категорий клиентов")),
      (!itsSegmentBlackList.value)
        ? (_openBlock(), _createElementBlock("p", {
            key: 2,
            innerHTML: paramsSemgnet.value.param1
          }, null, 8, _hoisted_7))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedCategories.value, (category) => {
              return (_openBlock(), _createElementBlock("span", {
                key: category.category_id
              }, _toDisplayString(category.title), 1))
            }), 128))
          ])),
      (!itsSegmentBlackList.value)
        ? (_openBlock(), _createElementBlock("p", {
            key: 4,
            innerHTML: paramsSemgnet.value.param2
          }, null, 8, _hoisted_9))
        : _createCommentVNode("", true),
      (!itsSegmentBlackList.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_10, " *Рассчитывается автоматически при помощи алгоритма ИИ "))
        : _createCommentVNode("", true)
    ]),
    btns: _withCtx(() => [
      (recomednationPageClientsShow.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn",
            style: {"border-top-left-radius":"0"},
            onClick: goReactivation
          }, " Реактивация "))
        : _createCommentVNode("", true),
      (
          recomednationPageClientsShow.value && downloadUrl.value && !isCurrentTarifTest.value
        )
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: "btn _green",
            href: downloadUrl.value
          }, " Скачать клиентов (.xlsx) ", 8, _hoisted_11))
        : (recomednationPageClientsShow.value && isCurrentTarifTest.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn _green",
              onClick: downloadExel
            }, " Скачать клиентов (.xlsx) "))
          : _createCommentVNode("", true),
      (recomednationPageClientsShow.value && false)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "btn _blue1",
            onClick: goToClientsInYclients
          }, " Клиенты сегмента в Yclients "))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      _withDirectives(_createElementVNode("iframe", {
        ref: "iframeRef",
        src: `https://b24-7zji69.bitrix24site.ru/${currentSegment.value.key}/`,
        class: "segment-recomendation__iframe",
        frameborder: "0"
      }, null, 8, _hoisted_12), [
        [_vShow, !recomednationPageClientsShow.value]
      ]),
      (recomednationPageClientsShow.value)
        ? (_openBlock(), _createBlock(segmentClients, {
            key: 0,
            paginationTab: paginationTab.value,
            currentTab: currentPaginationTab.value,
            onUpdateTab: updateTab,
            class: "segment-recomendation__clients",
            tabContentShow: tabContentShow.value
          }, {
            tabContent: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(blackListSettings)
              ])
            ]),
            _: 1
          }, 8, ["paginationTab", "currentTab", "tabContentShow"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["sectionsColor", "textColor"]))
}
}

})