<template>
  <div class="reactivation-page">
    <reactivationList v-if="!showSegmentActions"></reactivationList>
    <reactivationCreate v-else></reactivationCreate>
  </div>
</template>

<script setup>
import reactivationList from '@/components/reactivation/reactivationList.vue'
import reactivationCreate from '@/components/reactivation/reactivationCreate.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'

const store = useStore()
const showSegmentActions = computed(() => store.state.segments.showSegmentActions)

onMounted(() => {
  store.dispatch('reactivation/getReactivationStatuses')
})
</script>

<style>

</style>