import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "reactivation-template" }
const _hoisted_2 = { class: "reactivation-template__header" }
const _hoisted_3 = { class: "warn-content" }
const _hoisted_4 = { class: "reactivation-template__rools" }
const _hoisted_5 = { class: "fw-500" }
const _hoisted_6 = { class: "reactivation-accordion__content" }
const _hoisted_7 = { class: "reactivation-template__rools" }
const _hoisted_8 = { class: "fw-500" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "reactivation-template__example" }
const _hoisted_11 = { class: "reactivation-accordion__last" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "reactivation-accordion__last" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "reactivation-template__block" }
const _hoisted_19 = { class: "reactivation-template__block" }
const _hoisted_20 = { class: "reactivation-template__block" }

import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import emojiButton from "@/components/reactivation/emojiButton.vue";
import reactivationTemplateForm from "@/components/reactivation/reactivationTemplateForm.vue";
import reactivationTestForm from "@/components/reactivation/reactivationTestForm.vue";
import uiAccordion from "@/components/UI/ui-accordion.vue";
import { computed, onUnmounted, reactive, ref } from "vue";

import { templateAdvices, sendAdvices } from "@/assets/texts";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'reactivationTemplate',
  setup(__props) {

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);

const channels = ref(["Push", "WhatsApp"]);

function inputRedaction(channel, event) {
  console.dir(event);
}

let activeChannel = ref("Push");

const templates = reactive({
  Push: { text: "" },
  WhatsApp: { text: "" },
});

const isCurrentTarifTest = computed(
  () => store.state.tarif.currentTarif.test_tariff
);

function saveAndGoReactivation() {
  if (!isCurrentTarifTest.value) {
    store.state.reactivation.reactivationCreateStep = 2;
  } else {
    store.commit("tarif/setTariffBanner", true);
    router.push("/tariffs");
  }
}

function closeReactivation() {
  store.state.segments.showSegmentActions = false;
  store.state.reactivation.reactivationCreateStep = 0;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(uiAccordion, {
        defaultOpen: true,
        class: "header-accordion"
      }, {
        header: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("div", { class: "reactivation-template__warn" }, [
            _createTextVNode(" Важные рекомендации для безопасной рассылки в WhatsApp: "),
            _createElementVNode("span", { class: "reactivation-accordion__toggle" })
          ], -1)
        ])),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("ol", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sendAdvices).list, (rool, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(rool.name), 1),
                  _createTextVNode(" " + _toDisplayString(rool.text), 1)
                ]))
              }), 128))
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fw-500" }, " Соблюдение этих рекомендаций поможет снизить риски блокировки аккаунта и повысить эффективность рассылки. ", -1))
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(uiAccordion, { class: "reactivation-accordion" }, {
      header: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "reactivation-accordion__header" }, [
          _createTextVNode(" Как написать сообщение, на которое захочется ответить? "),
          _createElementVNode("span", { class: "reactivation-accordion__toggle" })
        ], -1)
      ])),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("ol", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(templateAdvices).list, (advice) => {
              return (_openBlock(), _createElementBlock("li", {
                key: advice.name
              }, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(advice.name), 1),
                _cache[4] || (_cache[4] = _createTextVNode()),
                _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("div", {
                  innerHTML: advice.text
                }, null, 8, _hoisted_9),
                _createElementVNode("div", _hoisted_10, _toDisplayString(advice.example), 1)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              class: "fw-500",
              innerHTML: _unref(templateAdvices).preLastBlock.title
            }, null, 8, _hoisted_12),
            _createElementVNode("div", {
              class: "reactivation-accordion__text",
              innerHTML: _unref(templateAdvices).preLastBlock.text
            }, null, 8, _hoisted_13)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", {
              class: "fw-500",
              innerHTML: _unref(templateAdvices).lastBlock.name
            }, null, 8, _hoisted_15),
            _createElementVNode("div", {
              class: "reactivation-accordion__text",
              innerHTML: _unref(templateAdvices).lastBlock.text
            }, null, 8, _hoisted_16)
          ]),
          _createElementVNode("div", {
            class: "reactivation-accordion__footer",
            innerHTML: _unref(templateAdvices).footerText
          }, null, 8, _hoisted_17)
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_18, [
      _createVNode(reactivationTemplateForm)
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createVNode(reactivationTestForm)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("button", {
        class: "btn _gray",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).state.reactivation.reactivationCreateStep = 0))
      }, " Назад "),
      _createElementVNode("button", {
        class: "btn",
        onClick: saveAndGoReactivation
      }, " Сохранить и запустить рассылку ")
    ])
  ]))
}
}

})