<template>
  <div
    class="page-sections-header"
    :style="`--page-sections-bg: ${sectionsColor[0]}; --text-top-color: ${
      textColor || '#000'
    };  --trans-text-color: ${transTextColor};`"
  >
    <div class="page-sections-header__top">
      <slot name="top"></slot>
      <button :class="closeBtnClass" v-if="closeBtnClass" @click="$emit('close')">
        Закрыть без изменений
      </button>
    </div>
    <header class="sections-header" :class="{ '_with-tab': headerTab }">
      <div class="sections-info">
        <div
          class="sections-info__block"
          :style="`background-color:${props.sectionsColor[0]}`"
        >
          <slot name="header1"></slot>
        </div>
        <div
          class="sections-info__block"
          :style="`background-color:${props.sectionsColor[1]}`"
        >
          <slot name="header2"></slot>
        </div>
        <div
          class="sections-info__block"
          :style="`background-color:${props.sectionsColor[2]}`"
        >
          <slot name="header3"></slot>
        </div>
      </div>
    </header>

    <div class="page-sections-header__btns">
      <slot name="btns"></slot>
    </div>

    <div class="page-sections-header__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits } from "vue";

type typeProps = {
  sectionsColor: Array<string>;
  closeBtnClass: string;
  textColor: string;
};



const props = withDefaults(defineProps<typeProps>(), {
  sectionsColor: () => ["#fff", "#fff", "#fff"],
  textColor: "#000",
});

const transTextColor = computed(() => addTransparency(props.textColor))

function addTransparency(color) {
  // Проверяем, является ли цвет в формате HEX
  if (color.startsWith("#")) {
    // Преобразуем HEX в RGB
    let r, g, b;
    if (color.length === 4) {
      r = parseInt(color[1] + color[1], 16);
      g = parseInt(color[2] + color[2], 16);
      b = parseInt(color[3] + color[3], 16);
    } else if (color.length === 7) {
      r = parseInt(color.slice(1, 3), 16);
      g = parseInt(color.slice(3, 5), 16);
      b = parseInt(color.slice(5, 7), 16);
    } else {
      throw new Error("pageWithSectionsHeader: Неправильный формат HEX цвета");
    }
    // Возвращаем цвет в формате rgba с прозрачностью 45%
    return `rgba(${r}, ${g}, ${b}, 0.45)`;
  }

  // Проверяем, является ли цвет в формате rgba
  if (color.startsWith("rgba")) {
    // Извлекаем значения r, g, b
    const rgbaValues = color.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d.]*)\)/
    );
    if (rgbaValues) {
      const r = rgbaValues[1];
      const g = rgbaValues[2];
      const b = rgbaValues[3];
      // Возвращаем цвет с новой прозрачностью 45%
      return `rgba(${r}, ${g}, ${b}, 0.45)`;
    }
  }

  throw new Error("pageWithSectionsHeader: Неподдерживаемый формат цвета");
}
</script>

<style scoped lang="scss">
$widthPage: 1080px;
$contentWidth: 1080px;
.page-sections-header {
  max-width: $widthPage;
  color: var(--text-top-color);
  &__top {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 27px;

    button.btn-type1 {
      flex-grow: 1;
      padding: 0;
    }
  }
  &__content {
    width: $contentWidth;
  }

  &__btns {
    padding-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    align-items: center;
    column-gap: 40px;
    row-gap: 20px;
  }
}
.sections-header {
  display: flex;
  border: 1px solid #ccc;
  flex-direction: column;
  border-radius: 19px;

  &._with-tab {
    .sections-info__block {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &__tab {
    width: 100%;

    display: flex;
    align-items: center;

    overflow: hidden;
    gap: 7px;
    padding: 5px 5px 0 5px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      text-align: center;
      background: rgba(254, 228, 171, 0.29);
      height: 35px;
      font-size: 16px;

      transition: 0.2s;
      cursor: pointer;

      &:hover,
      &.active {
        background: var(--page-sections-bg);
        text-shadow: 0 0 1px #333;
      }

      &:first-child {
        border-radius: 19px 0 0 0;
      }
      &:last-child {
        border-radius: 0 19px 0 0;
      }
    }
  }
}
.sections-info {
  display: inline-flex;
  gap: 7px;

  padding: 5px;

  justify-self: start;
  width: 100%;

  &__block {
    transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
    padding: 19px 30px;
    min-height: 138px;
    position: relative;
    &:nth-child(1) {
      border-radius: 15px 0 0 15px;
      width: 25%;
    }
    &:nth-child(2) {
      width: 36%;
    }
    &:nth-child(3) {
      border-radius: 0 15px 15px 0;
      min-width: 380px;
      flex-grow: 1;
    }
  }
}
</style>

<style lang="scss">
.sections-info {
  &__block {
    p {
      font-size: 16px;
      margin-bottom: 8px;
    }
    p.si-tit {
      margin-bottom: 14px;
      font-weight: 500;
    }
    p.si-ps {
      position: absolute;
      bottom: 0px;
      right: 10px;
      color: var(--trans-text-color);
      font-size: 10px;
      letter-spacing: 0.3px;
    }
    p.si-row {
      display: flex;
      justify-content: space-between;

      span:last-child {
        flex-grow: 1;
        max-width: 150px;
      }
    }
  }
}

.sections-header {
  .btn {
    &:nth-child(1) {
      align-self: start;
    }
    &:nth-child(2) {
      align-self: start;
    }
    &:nth-child(3) {
      align-self: end;
    }
    &:nth-child(4) {
      align-self: end;
    }
  }
}
</style>
